@font-face{
font-family:"Montserrat";
src:url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face{
font-family:"Montserrat-Bold";
src:url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face{
font-family:"Roboto Slab Bold";
src:url("../fonts/RobotoSlab-Bold.ttf") format("truetype");
}

@font-face{
font-family:"Lato Bold";
src:url("../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face{
font-family:"Lato";
src:url("../fonts/Lato-Black.ttf") format("truetype");
}

.montserrat-regular
{
	font-family:Montserrat;
}

.montserrat-bold
{
	font-family:Montserrat-Bold;
}

.RobotoSlab-bold
{
	font-family:Roboto Slab Bold;
}

.Lato-bold
{
	font-family:Lato Bold;
}

.lato
{
	font-family:Lato;
}
.bold{
	font-weight: bold !important;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url("../fonts/MaterialIcons-Regular.woff2") format('woff2'),
       url("../fonts/MaterialIcons-Regular.woff") format('woff'),
       url("../fonts/MaterialIcons-Regular.ttf") format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.material-icons.md-18 { font-size: 18px; }
html,
body {
  background: #fafafa;
  font-family: "Montserrat";
  color: #333;
  background: url("../images/Cover.png") no-repeat center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
* {
  /* So 100% means 100% */
  box-sizing: border-box;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  -moz-box-shadow: 0 0 10px #888;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  -webkit-box-shadow: 0 0 10px #888;
  -webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none;
}

.ribbon a {
  border: 1px solid #faa;
  color: #fff;
  display: block;
  font: bold 81.25% "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px;
}

.logo .logo-img {
  height: 45px;
  display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;
}

.hero-unit h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
}

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.voffset {
  margin-top: 2px;
}
.voffset1 {
  margin-top: 5px;
}
.voffset2 {
  margin-top: 10px;
}
.voffset3 {
  margin-top: 15px;
}
.voffset4 {
  margin-top: 30px;
}
.voffset5 {
  margin-top: 40px;
}
.voffset6 {
  margin-top: 60px;
}
.voffset7 {
  margin-top: 80px;
}
.voffset8 {
  margin-top: 100px;
}
.voffset9 {
  margin-top: 150px;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point:last {
  margin: 0 !important;
}
.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis;
}
.alert pre {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
}

.alert .popover pre {
  font-size: 10px;
}

.alerts .toast {
  position: fixed;
  width: 100%;
}

.alerts .toast.left {
  left: 5px;
}

.alerts .toast.right {
  right: 5px;
}

.alerts .toast.top {
  top: 55px;
}

.alerts .toast.bottom {
  bottom: 55px;
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.jh-table > tbody > tr > td {
  /* Align text in td vertically (top aligned by Bootstrap) */
  vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
  /* full visible sorting icons and pointer when mouse is over them */
  opacity: 1;
  cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px;
  }

  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0;
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

/* jhipster-needle-css-add-main JHipster will add new css style */

/* Angeline */

#loading-bar,
#loading-bar-spinner {
  display: none !important;
}

#loginpage {
  width: 380px;
  height: 150px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
#loginpage input{
	border:none;
}


#mainDiv {
  font-family: Montserrat;
  background: url("../images/Cover.png") no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}

/* .blueGradient{
	Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,c6e3f7+18,7390a4+52,284559+82,000000+100
background - color: #4C9DEE; Old browsers
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(198,227,247,1) 18%, rgba(115,144,164,1) 52%, rgba(40,69,89,1) 82%, rgba(0,0,0,1) 100%); FF3.6-15
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(198,227,247,1) 18%,rgba(115,144,164,1) 52%,rgba(40,69,89,1) 82%,rgba(0,0,0,1) 100%); Chrome10-25,Safari5.1-6
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(198,227,247,1) 18%,rgba(115,144,164,1) 52%,rgba(40,69,89,1) 82%,rgba(0,0,0,1) 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); IE6-9\
} */

.blackGradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#224c90+2,557fc3+29,557fc3+45,557fc3+55,00195d+100 */
  background: #224c90;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #224c90 2%, #557fc3 29%, #557fc3 45%, #557fc3 55%, #00195d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#224c90', endColorstr='#00195d', GradientType=0);
  /* IE6-9 */
}

.container {
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.75);
}

.loginbox {
  padding: 50px 55px;
}

.centerAlign {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0 auto;
}

.spinner {
	margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.112);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  }
  
  .spinner > div {
	width: 25px;
	height: 25px;
	background-color: #d9d9e7;
  
	border-radius: 100%;
	display: inline-block;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
	animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
	animation-delay: -0.16s;
  }
  
  @keyframes sk-bouncedelay {
	0%, 80%, 100% {
	  transform: scale(0);
	} 40% {
	  transform: scale(1.0);
	}
  }

.imageAlignment {
  text-align: center;
  padding-left: 135px;
  padding-bottom: 10px;
}

.fontsizes {
  font-size: 13px;
  color: #4a677b;
}

.inputAlign {
  height: 60px;
}

.cred-logo {
  width: 110px;
  margin: auto;
}

.logoContent {
  width: 150px;
  height: 22px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 22px;
  letter-spacing: 3px;
  color: #ffffff;
  margin-top: 26px;
  margin-bottom: 40px;
}

.loginContent {
  padding-top: 40px;
  padding-bottom: 45px;
  width: 287px;
  height: 19px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 19px;
  letter-spacing: 2px;
  color: #ffffff;
  margin: 0 auto;
}

.user {
  width: 280px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.14);
  border-radius: 2px;
}

#loginButton {
  width: 280px;
  height: 40px;
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 2px solid #F5C727;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 19px;
  color: #231f20;
  border: 2px solid #F5C727;
}

.textboxcolor {
  background-color: rgba(255, 255, 255, 0.14);
  border-color: rgba(255, 255, 255, 0.14);
  border-style: none;
  outline: none;
  padding: 8px 0 10px 10px;
  opacity: 0.74;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
}

::-webkit-input-placeholder {
  color: white;
  font-size: 10px;
}

.eyeIcon {
  margin-left: 245px;
  margin-top: 0;
  color: rgba(255, 255, 255, 0.3);
  font-size: 22px !important;
  position: relative;
  top: -28px;
}

.loginSubDiv {
  width: 74%;
  margin: 0 auto;
  padding-top: 10px;
}

.subDiv {
  padding-top: 15px;
  padding-bottom: 10px;
}

.signInDiv {
  margin-left: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.forgotPassDiv,
.forgotPassDiv:hover {
  padding: 0 0 0 48px;
  text-decoration: none !important;
  opacity: 0.3;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: 0.5px;
}

.reqAccessDiv {
  padding-left: 97px;
  font-size: 10px;
  text-decoration: none;
}

.errorDiv {
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.5);
  background-color: #cc6262;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  color: white;
  padding-left: 85px;
}

.p10 {
  padding-top: 20px;
}

.p11 {
  padding-left: 10px;
}

.profileimg {
  box-sizing: border-box;
  width: 122px;
  height: 122px;
  background-color: #ecf0f1;
  border: 1px solid #f4f8f9;
}

.tab-content {
  max-height: 550px;
}

.f2 {
  color: #43494c;
  font-weight: bold;
}

.imageRadius {
  width: 60px;
  height: 60px;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
}

.divStyle {
  width: 190px;
  height: 24px !important;
  background-color: #f5c727;
  border-radius: 63px;
  padding-top: 2px;
}

.box-content {
  display: inline-block;
  padding-top: 25px;
  margin-right: 30px;
}

.left {
  border-left: 1px solid #ccc;
}

.m0 {
  margin-top: 0;
}

.m10 {
  margin-top: 10px;
}

.profileLayout {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.investigationLayout {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.f1 {
  width: 168px;
  height: 16px;
  font-family: "Roboto Slab Bold";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  color: #163043;
}

.divider {
  box-sizing: border-box;
  width: 2px;
  height: 10px;
  border: 1px solid #d9dfe0;
}

.contractHeader {
  min-height: 130px;
  background-color: #f5f5f5;
  border-radius: 3px 3px 0 0;
}

.contractHeader .label {
  width: 310px;
  height: 26px;
  font-family: "Roboto Slab Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 1px;
  color: #43494c;
}

.contractHeader .reviewDiv {
  box-sizing: border-box;
  height: 25px;
  background-color: rgba(245, 199, 39, 0);
  border: 1px solid #f5c727;
  border-radius: 31px;
}

.contractHeader .reviewDiv .f2 {
  height: 13px;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  color: #43494c;
  margin: 0 14.5px 0 15.5px;
}

.contractHeader .dateDiv {
  width: 150px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
  color: #43494c;
}

.investigationDivider {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #c9cbce;
}

.investigationDetailsMain .label {
  width: 2px;
  height: 20px;
  font-family: "Roboto Slab Bold";
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 2px;
  color: #43494c;
}

.prospectMain {
  width: 1200px;
  height: 150px;
  background-color: #1a364b;
}

.prospectDivider {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #c9cbce;
}

.openformbtn {
  height: 40px;
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.openformbtn .div {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  color: #43494c;
  padding: 12px 10px 12px 10px;
}

.profileDiv {
  min-height: 400px;
  background-color: #ffffff;
  padding-left: 5%;
  padding-top: 25%;
}

.profileSubDiv {
  background-color: #ffffff;
  min-height: 100px;
  padding-left: 5%;
  padding-top: 33px;
}

.mr10Imp {
  margin-right: 10px !important;
}

.ml {
  margin-left: 10px;
}

.mt6 {
  margin-top: 6px;
}

.f3 {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  color: #43494c;
}

.prospectheader {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  color: #919ca1;
  height: 13px;
  opacity: 0.5;
}

.profileInvLabel {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  color: #919ca1;
}

.profileInvValue {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  color: #43494c;
}

.reportsHeader {
  font-family: "Roboto Slab Bold";
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 2px;
  color: #43494c;
}

.fontstyle {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.textstyle {
  font-family: Montserrat;
  font-size: 13px;
  line-height: 16px;
  color: #43494c;
}

.textstyle2 {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 16px;
  color: #43494c;
}

.textColor {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #2bb29a;
}

.mtopab50 {
  position: absolute;
  margin-top: 50px !important;
}

.profile {
  outline: 0;
  border: none;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
}

.backcolor {
  background-color: #f4f8f9;
}

.activity {
  outline: 0;
  border: none;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
}

@media handheld, only screen and (max-width: 450px) {
  .time {
    flex-direction: row-reverse;
  }

  .importFamilyPopup {
    min-width: 100% !important;
    min-height: 100% !important;
  }
}

/* Archana */

.radioChangePopupWidth {
  width: 580px;
}

.wid120 {
  width: 138px !important;
}

.done {
  margin-left: 3px;
  margin-top: 1px;
}

.mbn9 {
  margin-bottom: -9px;
}

.mtn17 {
  margin-top: -17px;
}

.copyLabel {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  color: #43494c;
}

.copyBtn {
  width: 153px;
  height: 33px;
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 53px;
}

.warnBtnWid {
  width: 240px !important;
}

.warnImage {
  height: 100px;
  width: 100px;
  background-color: #f6c62b;
  border-radius: 50px;
  margin: 0 auto;
}

.WarnSub {
  width: 362px;
  height: 40px;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  color: #3a3a3a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.confirmWarn {
  font-size: 24px;
}

.warnbtn {
  height: 40px;
  background-color: #f5c727 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.btnwidth {
  width: 135px;
}

.warningSub {
  width: 330px;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 20px;
  color: #3a3a3a;
  margin-bottom: 20px;
}

.blackCircle {
  width: 5px;
  height: 5px;
  background-color: #919ca1;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 7px;
}

.warningPopupWidth {
  width: 430px;
}

#capsFilter .camelCase,
#capsFilterLocation .camelCase {
  text-transform: uppercase;
}

.wi510 {
  width: 510px;
}

.wi470 {
  width: 470px !important;
}

.widthclass92 {
  width: 92% !important;
}

.widthclass {
  width: 100% !important;
}

#addNewEntityPopupId .filterSubHeading,
#requestLocationFormId .filterSubHeading {
  font-family: Montserrat !important;
  font-size: 15px !important;
  color: #919ca1 !important;
  text-transform: none !important;
}

#apprvEntityReq .require:after,
#addNewEntityPopupId .require:after {
  color: #919ca1;
  content: "*";
  padding-left: 5px;
}

.entityType {
  padding-top: 6px !important;
  margin-top: 6px !important;
  padding-right: 25px !important;
}

.bg-white {
  background-color: white !important;
}

.border {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
}

.iconsearch {
  margin-bottom: -30px;
}

.alignpb {
  padding: 0 !important;
  padding-right: 15px !important;
}

.alignleftpb {
  padding: 0 !important;
  margin-bottom: 15px !important;
  margin-top: 8px !important;
  margin-left: -12px !important;
  padding-right: 25px !important;
}

.drop-down {
  padding-left: 40px !important;
  cursor: pointer;
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  color: #43494c !important;
  border-bottom: 1px solid #c9cbce !important;
  float: none;
}

.colum:hover {
  background-color: #f4f8f9;
}

.formvalue {
  color: white;
  font-size: 13px;
  font-family: Montserrat;
}

.formStyle {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-family: Montserrat;
}

.dividerline {
  box-sizing: border-box;
  width: 174px;
  height: 2px;
  border: 1px solid #747c80;
}

.formUserName {
  margin-bottom: 7px;
  height: 48px;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 1px;
  color: #ffffff;
}

.contractInvestigation {
  color: white;
  font-size: 11px;
  margin-bottom: 25px;
}

.width {
  width: 132px;
}

.assign {
  background-color: #f5c727;
  font-family: "Roboto Slab Bold";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  color: #163043;
  cursor: pointer;
}

.padding25 {
  padding-top: 25px;
}

.marginLeft20 {
  margin-left: 20px;
}

.assignInvPadding {
  padding-top: 45px;
  padding-left: 32px;
  width: 280px;
}

.scroll {
  overflow-y: auto;
  height: 586px;
}

.modal-dialog {
  width: 856px !important;
}

.modal-body {
  position: relative;
  padding: 0;
}

.bgk {
  background: #21364b;
  color: white;
}

.Styles {
  border: 1px solid #f6df85;
  border-radius: 15px;
  padding-right: 6px;
  padding-left: 6px;
}

.has-error {
  border: 1px solid #cc6262;
}

.pt20 {
  padding-top: 20px;
  color: black;
}

.ph20 {
  padding: 0 20px;
}

.aligncenter {
  text-align: center;
  margin: 0 auto;
}

.imagesquare {
  width: 50px;
}

.color-green {
  background-color: #53b49c;
  height: 24px;
  border-radius: 30px;
  color: white;
  font-size: 10px;
  padding: 5px 20px;
}

.color-yellow {
  background-color: #f5c727 !important;
}

.color-grey {
  background-color: #d9dfe0;
}

.common {
  border-radius: 31px;
  height: 24px;
  color: white;
  font-size: 10px;
  padding: 5px 20px;
}

.common-width {
  width: 73px !important;
}

.mblCount {
  width: 73px;
  height: 22px;
  border-radius: 31px;
  font-size: 11px;
  font-family: Montserrat;
  font-weight: bold;
  text-align: center;
  line-height: 14px;
  color: #ffffff;
  padding-top: 4px;
}

/* Yoga */

.wordWrap {
  word-wrap: break-word;
}

/*STYLE FOR SESSION*/

.sessiondetails {
  display: none;
}

/*STYLE FOR FORM*/

.bmHeader {
  font-family: "Montserrat";
  font-size: 20px;
  color: #43494c;
  letter-spacing: 1.6;
  font-weight: bold;
}

.nextSec {
  height: 88px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 1px #c9cbce;
  border-radius: 0 0 3px 3px;
}

.nextBtn {
  width: 158px;
  height: 40px;
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 53px;
}

.nextLabel {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  margin: auto;
  margin-top: 10px;
  text-align: center;
  color: #43494c;
}

.formBorderStyle {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 3px 3px 3px;
}

.formAutoSave {
  height: 15px;
  opacity: 0.4;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  line-height: 15px;
  color: #43494c;
}

.formSubmit {
  width: 168px;
  height: 37px;
  background-color: #f5c727 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  color: #43494c !important;
}

.formSubmitWidth {
  width: 230px;
}

.width100px {
  width: 100px;
}

.backColorTrans {
  background-color: transparent;
}

.formSideContentColor {
  background-color: #1a364b !important;
}

.deleteBorder {
  border-radius: 25px;
}

/*STYLE FOR FORM ENDS*/

.w99p {
  width: 99%;
}

.mltPer {
  margin-left: 32px !important;
}

.cursorPointer {
  cursor: pointer;
}

.assignConfirmPopupWidth {
  width: 518px !important;
}

.assignListWidth {
  width: 90px;
}

.assignWidth {
  width: 900px;
}

.profileHeight {
  height: 990px;
}

.mobileNav {
  line-height: 4;
  background-color: transparent;
}

.mobileNavBar {
  background: url("../images/Cover.png") no-repeat center;
}

.accountNav {
  display: block;
  height: 15px !important;
}

.act-sub-header {
  padding-bottom: 2px !important;
}

.activityDateMapBG {
  width: 100px;
  height: 24px;
  background-color: #f5c727;
  border-radius: 63px;
  padding: 4px 20px;
}

.activityDateMap {
  font-family: "Roboto Slab Bold";
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  letter-spacing: 1px;
  color: #163043;
}

.grncolor {
  color: #2bb29a !important;
}

.lgreycolor {
	color: black !important;
    margin-left: 3px;
}

.color-red {
  background-color: #e94949 !important;
}

.activityDateTime {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  color: #acacac;
  white-space: nowrap;
}

.activityAssigner {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  color: #43494c;
}

.redcolor {
  color: #e94949 !important;
}

.pd50Entity {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
}

.width850px {
  width: 850px;
}

.popupFontEntity {
  font-family: Montserrat;
  font-size: 20px;
  line-height: 19px;
  margin-left: 35px !important;
  font-weight: bold;
}

.activityStatusChange {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  color: #43494c;
}

.activityDivider {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #d9dfe0;
}

.mw25p {
  min-width: 25% !important;
}

.mw45p {
  min-width: 45% !important;
}

.mw16p {
  min-width: 16% !important;
}

.widthM35p {
  min-width: calc(100% - 35px) !important;
}

.widthM56p {
  min-width: calc(100% - 56px) !important;
}

.closedDiv {
  height: 24px;
  background-color: #f5c727;
  border-radius: 63px;
  padding-top: 2px;
}

.arrow_box {
  position: relative;
}

.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 38%;
  top: -4%;
  border: solid transparent;
  content: " ";
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fdfdff;
  border-width: 9px;
}

.drop-search {
  width: 252px;
  margin: 10px 0 -8px 25px;
}

.reportLabel {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.reportStatus {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.reportName {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  color: #919ca1;
}

.reportName2 {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 16px;
  color: #919ca1;
}

.reportView {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #2bb29a;
}

.submittedCircleGrn {
  border-radius: 100%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #f5c727;
}

.reportViewGry {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #bababa;
}

.closeShow {
  width: 37px;
  height: 14px;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  color: #bababa;
  padding: 5px 0 5px 15px;
}

.mb30M {
  margin-bottom: 0;
}

.mb8px {
  margin-bottom: 8px;
}

.left-drop-icon {
  position: absolute;
  padding-left: 10px;
  width: 16px;
  height: 16px;
  opacity: 0.2;
  color: #231f20;
}

.left-drop-search {
  padding-left: 40px !important;
  padding-bottom: 8px !important;
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  color: #43494c !important;
  border-bottom: 1px solid #c9cbce !important;
  float: none;
  cursor: text;
}

.filterHeading {
  height: 50px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
  color: #43494c;
}

.filterSubHeading {
  height: 25px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 800;
  line-height: 40px;
  color: #43494c;
}

.filters {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: #43494c;
}

.filtersCount {
  text-align: right;
}

.dividerStyle {
  box-sizing: border-box;
  border-width: 2px;
  color: #747c80;
  margin: 15px 0 15px 0;
}

.leftButton {
  width: 100%;
  height: 56px;
  background-color: #f5c727 !important;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
}

.leftButtonText {
  width: 100px;
  height: 19px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 19px;
  letter-spacing: 1px;
  color: #43494c;
}

.searchnote {
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
  color: #bababa;
  text-align: left;
  margin-left: 28px;
  margin-bottom: 15px;
}

.username-Left {
  height: 22px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
}

.userPosition-Left {
  width: 58px;
  height: 18px;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
}

.tabHeader-Left {
  width: 138px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 48px;
  color: #ffffff;
  opacity: 0.4;
  cursor: pointer;
}

.navTabHeader {
  width: 473px;
}

.navAccountArrow {
  opacity: 0.5;
  margin-top: -4px;
  cursor: pointer;
}

.navAccountDropMenu {
  display: block !important;
}

.tgRightArrow {
  opacity: 0.2;
  color: white;
}

.tgRightHeaderPosition {
  position: fixed;
  bottom: 2px;
}

.tgRightContent {
  margin: 3px 9px;
}

.filterPannel {
  min-width: 340px;
}

.indexContent {
  padding: 0;
  border: none;
  background: none;
  overflow: hidden;
}

.tgRightHeader {
  width: 62px;
  height: 22px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
  margin-top: 30px;
}

.tgRightTitle {
  width: 58px;
  height: 18px;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
}

.tgRightHeaderHgt {
  height: 90px;
}

.tgRightAllign {
  padding: 0 16px;
}

.applyFilter {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 19px;
  letter-spacing: 1px;
  color: #43494c;
}

.capitalize::first-letter {
  text-transform: uppercase;
}

.dropdown-toggle {
  cursor: pointer;
  color: white;
}

/* Sundar */

.prospectName {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.investigationsType {
  opacity: 0.6;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  color: #747c80;
  letter-spacing: 1px;
}

.invHeader {
  height: 39px;
  font-family: Montserrat;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 1px;
  color: #ffffff;
}

.smallCircle {
  width: 10px;
  height: 10px;
  background-color: #f5c727;
  border-radius: 5px;
  margin-right: 10px;
}

.status {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  color: #43494c;
}

.statusName {
  font-family: Lato Bold;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: #ffffff;
}

.bgColor {
  background-color: #193549 !important;
}

.carddemoBasicUsage .card-media {
  background-color: #999999;
}

.footer {
  background-color: #f5f5f5;
}

.padding15 {
  padding: 15px;
}

.orangeIcon {
  width: 15px;
  height: 15px;
  background-color: orange;
  border-radius: 50%;
}

.paddingLeft {
  padding-left: 20px;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 22px;
  color: white;
  line-height: 50px;
  text-align: center;
}

.pdLeftSize {
  padding-left: 10px;
  padding-top: 5px;
}

.bgc-blue {
  background: #193549;
}

.th-select {
  opacity: 1 !important;
}

.tc-white {
  color: #ffffff;
}

.tc-grey {
  color: #808080;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  cursor: pointer;
}

.lariHeader {
  width: 38px;
  height: 20px;
  opacity: 0.6;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  line-height: 23px;
  letter-spacing: 1px;
  color: #ffffff;
}

.lariLabel {
  width: 140px;
  height: 21px;
  background-color: #f5c727;
  border-radius: 3px;
  margin-left: 9px;
  padding-top: 2px;
  text-align: center;
}

.labelContent {
  height: 16px;
  font-family: "Roboto Slab Bold";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  color: #163043;
}

.tabHeader {
  width: 119px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #ffffff;
  opacity: 0.4;
  cursor: pointer;
  letter-spacing: 1px;
}

.spaceAllign {
  padding-right: 75px;
  padding-left: 70px;
}

.spaceAllignContent {
  padding-right: 75px;
  padding-left: 70px;
}

.userName {
  height: 15px;
  opacity: 0.5;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  line-height: 15px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
}

.widandhei {
  height: 21px;
}

.prospectsImg {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  font-size: 22px;
  background-color: #2bb29a;
  line-height: 41px;
  text-align: center;
  color: #ffffff;
}

.marginleri {
  margin-left: 30px;
  margin-right: 30px;
}

.searchLeft {
  padding-left: 30px !important;
}

.marginLeftZero {
  margin-left: 0 !important;
}

.marginLeft40 {
  margin-left: 40px;
}

.mrgBottom30 {
  margin-bottom: 30px;
}

.contentWidth {
  width: 100%;
  max-width: 1500px;
}

.searchIcon {
  color: #231f20;
  opacity: 0.2;
  position: absolute;
}

.moreVertIcon {
  color: #919ca1;
  cursor: pointer;
  font-weight: bold;
}

.moreVertIcon:hover {
  color: #43494c;
}

.colorWh {
  color: #ffffff;
}

.bgColorWh {
  background-color: #ffffff !important;
}

@media only screen and (max-width: 960px) and (min-width: 651px) {
  .profileMarLeft {
    margin-left: 7%;
  }

  .ml2P {
    margin-left: 1.8%;
  }

  .iconLeft {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 960px) {
  .investigationContainer {
    margin-left: 0 !important;
    padding: 0 5px;
  }

  .invHeader {
    font-size: 24px !important;
    padding-left: 0 !important;
  }

  .invBottom {
    margin-bottom: 47px !important;
  }

  .invLeft {
    margin-left: 10px !important;
    padding-left: 0 !important;
  }

  .investigationMargin {
    margin-left: 16px;
    margin-right: 16px;
  }

  .investigationHeader {
    margin-top: 18px !important;
  }

  .mblSideFilter {
    margin-top: 0 !important;
    padding: 30px;
  }

  .rghtAlign {
    margin-right: 0 !important;
  }

  .signInContent {
    font-size: 14px;
  }

  .paddingV15 {
    padding-left: 0 !important;
  }

  .respArrow {
    margin-left: 5px !important;
  }

  .paddingT {
    padding-top: 10px !important;
  }

  .profileWidth {
    width: 373px !important;
  }

  .mb30M {
    margin-bottom: 30px !important;
  }

  .p0i {
    padding: 0 !important;
  }

  .bgi {
    background-color: #1a364b !important;
  }

  .mw100i {
    max-width: 100% !important;
  }

  .formSidemt0i {
    margin-top: 0 !important;
  }

  .assignInvPadding {
    width: 100% !important;
  }

  .margin50 {
    margin: 10px !important;
  }

  .assgnInv {
    font-size: 16px !important;
    line-height: 16px !important;
    letter-spacing: 2px !important;
    margin-bottom: 5px !important;
  }

  .height900 {
    height: 900px;
  }

  .profileSideHeight {
    height: 100% !important;
  }

  .profileContent {
    margin-left: 4.5%;
  }

  .profileDetailsSec {
    max-width: 91%;
  }

  .UserProfileInfo {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .navHead {
    background: none !important;
  }

  .replyPadding {
    margin-top: 30px;
  }

  .replyPaddingBottom {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 961px) {
  .profileSideTop {
    margin-top: 50px;
  }

  .profileDetailsSec {
    max-width: 69%;
  }

  .closedPadding {
    padding: 3px 8px !important;
  }

  .mw45p {
    min-width: 45% !important;
  }

  .userProspect {
    margin-left: 4% !important;
  }

  .replyPadding {
    margin-top: 15px;
  }

  .SideCommentsDiv {
    max-height: 350px;
  }
}

.investigationHeader {
  margin-top: 39px;
  margin-bottom: 23px;
}

.paddingV15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 30px;
}

@media only screen and (max-width: 1360px) and (min-width: 961px) {
  .pagePadding {
    margin-right: 30px;
  }

  .prospectPad {
    padding: 0 4% 0 2.5% !important;
  }

  .profileContent {
    padding-left: 0.1% !important;
  }

  .widthProfile {
    width: calc(100% - 65px) !important;
  }

  .activitypad {
    margin-left: 17%;
    margin-right: 16%;
  }

  .userProfileWidth {
    width: calc(100% - 56px) !important;
  }

  .profileMarLeft {
    margin-left: 4.8% !important;
  }

  .userProspect {
    margin-left: 2.5% !important;
  }

  .userProspectProfile {
    margin-left: 0.1% !important;
  }

  .UserProfileInfo {
    margin-left: 4%;
    padding-left: 0 !important;
  }

  .userProfilePadding {
    padding-left: 30px;
    padding-top: 30px;
  }

  .width95p {
    width: 95%;
  }

  .usersProfileHeight {
    min-height: 620px !important;
  }

  .formSideML {
    margin-left: -6.2%;
  }

  .profileDetailsSec {
    max-width: 70%;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1361px) {
  .pagePadding {
    margin-right: 30px;
  }

  .prospectPad {
    padding: 0 3% 0 2.5% !important;
  }

  .profileContent {
    padding-left: 0.2% !important;
  }

  .widthProfile {
    width: calc(100% - 85px) !important;
  }

  .activitypad {
    margin-left: 18%;
    margin-right: 17%;
  }

  .userProfileWidth {
    width: calc(100% - 56px) !important;
  }

  .profileMarLeft {
    margin-left: 4.5% !important;
  }

  .userProspectProfile {
    margin-left: 0.1% !important;
  }

  .userProspect {
    margin-left: 2.3% !important;
  }

  .userProfilePadding {
    padding-left: 30px;
    padding-top: 30px;
    margin-left: 2.3%;
  }

  .userFilterML {
    margin-left: 0.1%;
  }

  .formSideML {
    margin-left: -5.4%;
  }

  .profileDetailsSec {
    max-width: 73%;
  }
}

@media only screen and (min-width: 1024px) {
  .pright30 {
    padding-right: 30px !important;
  }

  .width95p {
    width: 95%;
  }

  .widthM56p {
    min-width: calc(100% - 56px) !important;
  }
}

@media only screen and (min-width: 1600px) {
  .mltPer {
    margin-left: 6.9% !important;
  }

  .activitypad {
    margin-left: 15.8%;
    margin-right: 14.8%;
  }

  .profileMarLeft {
    margin-left: 7% !important;
  }

  .formSideML {
    margin-left: -5.2%;
  }

  .prospectPad {
    padding: 0 4% 0 5% !important;
  }

  .profileContent {
    padding-left: 3.1% !important;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1930px) {
  .prospectPad {
    padding: 0 12% 0 11.8% !important;
  }

  .profileContent {
    padding-left: 10.2% !important;
  }

  .activitypad {
    margin-left: 21.4%;
    margin-right: 20.4%;
  }

  .pagePadding {
    margin-right: 30px;
  }

  .userProfileWidth {
    width: calc(100% - 226px) !important;
  }

  .userProspect {
    margin-left: 11.8% !important;
  }

  .userProspectProfile {
    margin-left: 10.2% !important;
  }

  .userProspectPadding {
    padding-right: 12% !important;
  }

  .userProfilePadding {
    padding-left: 30px;
    padding-top: 30px;
    margin-left: 11.6% !important;
  }

  .width95p {
    width: 95% !important;
  }

  .userFilterML {
    margin-left: 10.5%;
  }

  .profileMarLeft {
    margin-left: 13.5% !important;
  }

  .formSideML {
    margin-left: 5.8%;
  }
}

@media only screen and (min-width: 1931px) and (max-width: 2000px) {
  .prospectPad {
    padding: 0 12.9% 0 14.1% !important;
  }

  .profileContent {
    padding-left: 12.5% !important;
  }

  .widthProfile {
    width: calc(100% - 285px) !important;
  }

  .activitypad {
    margin-left: 22.7%;
    margin-right: 21.7%;
  }

  .userProfileWidth {
    width: calc(100% - 94px) !important;
  }

  .userProspect {
    margin-left: 14% !important;
  }

  .userProspectProfile {
    margin-left: 12.6% !important;
  }

  .userProfilePadding {
    padding-left: 30px;
    padding-top: 30px;
    margin-left: 12.6% !important;
  }

  .userFilterML {
    margin-left: 13%;
  }

  .profileMarLeft {
    margin-left: 15.5% !important;
  }

  .formSideML {
    margin-left: 8.2%;
  }
}

@media only screen and (min-width: 2001px) and (max-width: 2200px) {
  .prospectPad {
    padding: 0 16.3% 0 17.2% !important;
  }

  .profileContent {
    padding-left: 15.8% !important;
  }

  .widthProfile {
    width: calc(100% - 27%) !important;
  }

  .activitypad {
    margin-left: 25%;
    margin-right: 24%;
  }

  .userProfileWidth {
    width: calc(100% - 105px) !important;
  }

  .userProspect {
    margin-left: 17% !important;
  }

  .userProspectProfile {
    margin-left: 16% !important;
  }

  .userProfilePadding {
    padding-left: 30px;
    padding-top: 30px;
    margin-left: 15.6% !important;
  }

  .profileMarLeft {
    margin-left: 18.6% !important;
  }

  .formSideML {
    margin-left: 11.8%;
  }
}

.smoothScroll {
  -webkit-overflow-scrolling: touch;
}

.displayNone {
  display: none !important;
}

.dropDownValue {
  font-size: 12px;
  font-weight: 900;
  font-family: Lato;
  color: #35b098;
  padding-top: 7px;
  margin-bottom: 17px;
  padding-left: 2px;
}

.colop {
  color: #747c80;
  opacity: 0.5;
}

.stickySearch {
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
}

.sticky {
  height: calc(100vh - 500px);
  max-height: 100px;
  overflow-y: auto;
  width: 100%;
}

.ofH {
  overflow: hidden;
}

.noHScroll {
  overflow-x: hidden !important;
}

.bottomFixed {
  bottom: 0 !important;
  position: fixed;
}

.width100 {
  width: 100%;
}

.width100Imp {
  width: 100% !important;
}

/* InvestigationForm Design's CSS */

.sectionList {
  height: 0.43%;
  background-color: #ffffff;
  border-radius: 50%;
}

.dividerDiv {
  box-sizing: border-box;
  width: 740px;
  height: 2px;
  border: 1px solid #c9cbce;
}

.birthCertificateHeader {
  text-align: center;
  padding: 27px;
  font-family: Roboto Slab;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 1px;
  color: #43494c;
}

.instutionQ {
  width: 375px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
  margin-left: 24px;
}

/**Form */

.dividerDiv {
  box-sizing: border-box;
  width: 740px;
  height: 2px;
  border: 1px solid #c9cbce;
}

.bcHeader {
  font-family: "Montserrat";
  font-size: 20px;
  color: #43494c;
  letter-spacing: 1.6;
  font-weight: bold;
}

.questionCircle {
  width: 32px;
  height: 32px;
  background-color: #f7f7f7;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}

.greenClr {
  background-color: #2bb29a !important;
}

.lytGreyClr {
  background-color: #c9cbce;
}

.whiteClr {
  color: #ffffff !important;
}

.changeColor {
  background-color: #2bb29a !important;
  color: #ffffff !important;
}

.questionNumber {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  color: #43494c;
}

.quesStyle {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.insName {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  color: #3f3f3f;
  letter-spacing: 0.7;
}

.dividerDivTxt {
  margin-top: -27px;
  width: 580px;
}

.registryContent {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 24px;
  color: #43494c;
}

.commentBox {
  width: 580px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid #919ca1;
  border-radius: 2px;
}

.foo {
  resize: none;
}

.interviewTxt {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  color: #43494c;
}

.recordTxt {
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  color: #43494c;
}

.additionalInterviewTxt {
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #2bb29a;
  cursor: pointer;
}

.bcMain {
  width: 100%;
  max-width: 740px;
}

.mobLeft {
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 100% !important;
}

.pmMob {
  padding: 15px !important;
  margin-left: 40px !important;
}

.fmSummary {
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 1px;
  color: #43494c;
}

.colorBoldBlack {
  color: #43494c;
}

.cursorP {
  cursor: pointer;
}

.saveAndNext {
  width: 740px;
  height: 88px;
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 0 0 #c9cbce;
  border-radius: 0 0 3px 3px;
}

@media only screen and (max-width: 600px) {
  .p20i {
    padding: 20px !important;
  }

  .mt0i {
    margin-top: 0 !important;
  }

  .mt9i {
    margin-top: 9px !important;
  }

  .mt10i {
    margin-top: 10px !important;
  }

  .mt12i {
    margin-top: 12px !important;
  }

  .mt20i {
    margin-top: 20px !important;
  }

  .mt25i {
    margin-top: 25px !important;
  }

  .mb10i {
    margin-bottom: 10px !important;
  }

  .ml0i {
    margin-left: 0 !important;
  }

  .fz20i {
    font-size: 20px !important;
  }

  .mw200i {
    max-width: 200px !important;
  }

  .fs17i {
    font-size: 16px !important;
  }

  .fz18i {
    font-size: 18px !important;
  }

  .ml25px {
    margin-left: 25px !important;
  }

  .headCountFilterCls {
    margin-right: 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .mw200i {
    max-width: 200px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 2000px) {
  .headCountFilterCls {
    margin-right: 30px;
  }

  .width95p {
    width: 95%;
  }
}

@media only screen and (max-width: 650px) and (min-width: 300px) {
  .popup {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .assignSub {
    font-size: 10px !important;
  }

  .confirmAssign {
    font-size: 14px !important;
    text-align: center !important;
  }

  .profileDetailsSec {
    max-width: 96%;
  }

  .profilemt {
    margin-top: 0 !important;
  }

  .pb2p {
    padding-bottom: 18% !important;
  }

  .pb3p {
    padding-bottom: 10% !important;
  }

  .pb12p {
    padding-bottom: 8%;
  }

  .mRequestT50 {
    margin-top: 50px !important;
  }

  .mRequestT0 {
    margin-top: 0 !important;
    margin-bottom: -67px !important;
  }

  .mobileEditmt {
    margin-top: 0 !important;
    margin-bottom: -70px !important;
  }

  .mobileEditWidth100 {
    width: 100% !important;
  }

  .mobileEditmt70 {
    margin-top: 70px !important;
  }

  .mobileEditmt40 {
    margin-top: 40px !important;
  }

  .width95p {
    width: 95% !important;
  }

  .navHead {
    background: none !important;
  }

  .locationApplyBtnStyMbl {
    position: relative !important;
  }

  .mt8MBL {
    margin-top: 8% !important;
  }

  .pr30impMBL {
    padding-right: 30px !important;
  }

  .h56MBL {
    height: 56% !important;
  }

  .h65MBL {
    height: 65% !important;
  }

  .pt15MBL {
    padding-top: 15px !important;
  }

  .mt30MBL {
    margin-top: 30px !important;
  }

  .accordianNameHolderMBL {
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .accordianCircle {
    width: 30px;
    height: 30px;
  }

  .accordianCircleContent {
    font-size: 18px;
  }

  .openformbtn {
    margin: 0 !important;
  }

  .openformbtn .div {
    font-size: 13px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .fs15MBL {
    font-size: 15px !important;
    letter-spacing: 1px !important;
  }

  .mobileEditpb55 {
    padding-bottom: 55px !important;
  }

  .mobileEditmt55 {
    margin-top: 55px !important;
  }

  .bkP15 {
    background-position: 15px !important;
  }

  .pl0MBL {
    padding-left: 0 !important;
  }

  .pt2MBL {
    padding-top: 2px !important;
  }

  .ls0MBL {
    letter-spacing: 0 !important;
  }

  .m20MBL {
    margin: 20px !important;
  }

  .ml35MBL {
    margin-left: 35px !important;
  }

  .wsnormalMBL {
    white-space: normal !important;
  }

  .ml0MBL {
    margin-left: 0 !important;
  }

  .mw90MBL {
    max-width: 90% !important;
  }

  .rcActivity {
    line-height: 15px !important;
  }

  .iconLeft {
    margin-left: 10% !important;
  }

  .pl9per {
    padding-left: 14% !important;
  }

  .mh140 {
    min-height: 140px !important;
  }

  .mw150MBL {
    min-width: 200px !important;
  }

  .w30MBL {
    width: 30px !important;
  }

  .prosMBL {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .w100pMBL {
    width: 100% !important;
  }

  .pl8imp {
    padding-left: 8px !important;
  }

  .pl12per {
    padding-left: 6% !important;
  }
}

.assgnInv {
  font-family: "Roboto Slab";
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 3px;
  color: #43494c;
  margin-bottom: 25px;
  margin-top: 30px;
}

.margin50 {
  margin: 30px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.assignLoader {
  height: 586px;
}

.popupclose {
  width: 16px;
  height: 27px;
  font-size: 22px;
  text-align: center;
  line-height: 27px;
  letter-spacing: 1px;
  color: #bababa;
}

.divid {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #747c80;
  margin-right: 12px;
}

.assignSub {
  width: 362px;
  height: 40px;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  color: #3a3a3a;
  margin-top: 10px;
  margin-bottom: 20px;
}

.fabButton {
  position: relative;
  top: 25px;
}

/* Senthil */

.bktrans {
  background: transparent;
}

.white-text {
  color: #fff;
}

.bg-grey {
  background-color: grey;
}

.caps {
  text-transform: uppercase !important;
}

.capslow {
  text-transform: lowercase;
}

.fontW600 {
  font-weight: 600;
}

.fontW900 {
  font-weight: 900;
}

.fontW400 {
  font-weight: 400 !important;
}

.fz10 {
  font-size: 10px !important;
}

.fz11 {
  font-size: 11px !important;
}

.fz12 {
  font-size: 12px !important;
}

.fz13 {
  font-size: 13px !important;
}

.fz14 {
  font-size: 14px !important;
}

.fz15 {
  font-size: 15px !important;
}

.fz16 {
  font-size: 16px !important;
}

.fz18 {
  font-size: 18px !important;
}

.fz19 {
  font-size: 19px !important;
}

.fz20 {
  font-size: 20px !important;
}

.fz22 {
  font-size: 22px !important;
}

.fz24 {
  font-size: 24px !important;
}

.fz26 {
  font-size: 26px !important;
}

.fz30 {
  font-size: 30px;
}

.fz40 {
  font-size: 40px;
}

.fwb {
  font-weight: bold;
}

.monstFamily {
  font-family: Montserrat;
}

.robotoFamily {
  font-family: "Roboto Slab";
}

.lineHeight18 {
  line-height: 18px;
}

.lineHeight20 {
  line-height: 20px;
}

.overFlowAuto {
  overflow: auto;
}

.overFlowHidden {
  overflow: hidden;
}

input[type="text"]:disabled {
  border-bottom: 1px solid #9e9e9e !important;
}

.font-Regular {
  font-family: "Avenir Next LT Pro Regular" !important;
}

/************************************************************************************************************** */

/* ----------------------	MARGIN AND PADDING UNITS ----------------------	*/

/************************************************************************************************************** */

.m0imp {
  margin: 0 !important;
}

.m0 {
  margin: 0;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m20imp {
  margin: 20px !important;
}

.m25 {
  margin: 25px;
}

.m30 {
  margin: 30px;
}

.m35 {
  margin: 35px;
}

.m40 {
  margin: 40px;
}

.m50 {
  margin: 50px;
}

.m50imp {
  margin: 50px !important;
}

.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mv5 {
  margin: 5px 0;
}

.mv10 {
  margin: 10px 0;
}

.mv15 {
  margin: 15px 0;
}

.mv20 {
  margin: 20px 0;
}

.mv25 {
  margin: 25px 0;
}

.mv30 {
  margin: 30px 0;
}

.mv35 {
  margin: 35px 0;
}

.mv40 {
  margin: 40px 0;
}

.mh0 {
  margin-left: 0;
  margin-right: 0;
}

.mh5 {
  margin: 0 5px;
}

.mh10 {
  margin: 0 10px;
}

.mh15 {
  margin: 0 15px;
}

.mh18 {
  margin: 0 18px;
}

.mh20 {
  margin: 0 20px;
}

.mh25 {
  margin: 0 25px;
}

.mh30 {
  margin: 0 30px;
}

.mh35 {
  margin: 0 35px;
}

.mh40 {
  margin: 0 40px;
}

.mh10imp {
  margin: 0 10px !important;
}

.m45p {
  margin: 10px 10px 10px 45%;
}

.mt0 {
  margin-top: 0;
}

.mt0imp {
  margin-top: 0 !important;
}

.mt0p {
  margin-top: 0 !important;
}

.mt2 {
  margin-top: 2px;
}

.mt3 {
  margin-top: 3px;
}

.mt4 {
  margin-top: 4px !important;
}

.mtm4 {
  margin-top: -4px;
}

.mt5 {
  margin-top: 5px;
}

.mtn5 {
  margin-top: -5px;
}

.mt7 {
  margin-top: 7px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt11 {
  margin-top: 11px;
}

.mt12 {
  margin-top: 12px;
}

.mt15 {
  margin-top: 15px;
}

.mt18 {
  margin-top: 18px;
}

.mt20 {
  margin-top: 20px;
}

.mt20imp {
  margin-top: 20px !important;
}

.mt22 {
  margin-top: 22px;
}

.mt24 {
  margin-top: 24px;
}

.mt17imp {
  margin-top: 24px !important;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mt70 {
  margin-top: 70px;
}

.mtm7 {
  margin-top: -7px;
}

.mtm5 {
  margin-top: -5px;
}

.mtm13 {
  margin-top: -13px;
}

.mtm20 {
  margin-top: -20px;
}

.ml0 {
  margin-left: 0;
}

.ml5 {
  margin-left: 5px;
}

.ml7 {
  margin-left: 7px;
}

.ml8 {
  margin-left: 8px;
}

.ml10 {
  margin-left: 10px;
}

.ml12 {
  margin-left: 12px;
}

.ml3 {
  margin-left: 3px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml18 {
  margin-left: 18px;
}

.ml20 {
  margin-left: 20px;
}

.ml23 {
  margin-left: 23px;
}

.ml24 {
  margin-left: 24px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml32 {
  margin-left: 32px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml41 {
  margin-left: 41px;
}

.ml45 {
  margin-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml107 {
  margin-left: 107px;
}

.ml135 {
  margin-left: 135px;
}

.mlm7 {
  margin-left: -7px;
}

.mlm9 {
  margin-left: -9px;
}

.mlm11 {
  margin-left: -11px;
}

.mlm15 {
  margin-left: -15px;
}

.mlm25 {
  margin-left: -25px;
}

.mr0 {
  margin-right: 0;
}

.mr2 {
  margin-right: 2px;
}

.mr4 {
  margin-right: 4px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr12 {
  margin-right: 12px;
}

.mr13 {
  margin-right: 13px;
}

.mr15 {
  margin-right: 15px;
}

.mr16 {
  margin-right: 16px;
}

.mr18 {
  margin-right: 18px;
}

.mr20 {
  margin-right: 20px;
}

.mr20Imp {
  margin-right: 20px !important;
}

.mr24 {
  margin-right: 24px;
}

.mr25 {
  margin-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr66 {
  margin-right: 66px;
}

.mb0 {
  margin-bottom: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.mb6 {
  margin-bottom: 6px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb18 {
  margin-bottom: 18px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mbt0 {
  margin-bottom: 0 !important;
}

.mbt5 {
  margin-bottom: 5px !important;
}

.mbt20 {
  margin-bottom: 20px !important;
}

.mbt24 {
  margin-bottom: 24px !important;
}

.mbt25 {
  margin-bottom: 25px !important;
}

.mbt26 {
  margin-bottom: 26px !important;
}

.mbt35 {
  margin-bottom: 35px !important;
}

.mbt40 {
  margin-bottom: 40px !important;
}

.mbt50 {
  margin-bottom: 50px !important;
}

.mbt15 {
  margin-bottom: 15px !important;
}

.mbt16 {
  margin-bottom: 16px !important;
}

.mbt17 {
  margin-bottom: 17px !important;
}

.mbt27 {
  margin-bottom: 27px !important;
}

.mbt18 {
  margin-bottom: 18px !important;
}

.mbt30 {
  margin-bottom: 30px !important;
}

.mbt38 {
  margin-bottom: 38px !important;
}

.mbt60 {
  margin-bottom: 60px !important;
}

.p0 {
  padding: 0;
}

.p4 {
  padding: 4px;
}

.p5 {
  padding: 5px;
}

.p6 {
  padding: 6px;
}

.p8 {
  padding: 8px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p25 {
  padding: 25px;
}

.p30 {
  padding: 30px;
}

.p35 {
  padding: 35px;
}

.p40 {
  padding: 40px;
}

.p45 {
  padding: 45px;
}

.p50 {
  padding: 50px;
}

.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pv5 {
  padding: 5px 0;
}

.pv10 {
  padding: 10px 0;
}

.pv15 {
  padding: 15px 0;
}

.pv20 {
  padding: 20px 0;
}

.pv25 {
  padding: 25px 0;
}

.pv30 {
  padding: 30px 0;
}

.pv35 {
  padding: 35px 0;
}

.pv40 {
  padding: 40px 0;
}

.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.ph4 {
  padding: 0 4px;
}

.ph5 {
  padding: 0 5px;
}

.ph8 {
  padding: 0 8px;
}

.ph10 {
  padding: 0 10px;
}

.ph15 {
  padding: 0 15px;
}

.ph20 {
  padding: 0 20px;
}

.ph25 {
  padding: 0 25px;
}

.ph30 {
  padding: 0 30px;
}

.ph35 {
  padding: 0 35px;
}

.ph40 {
  padding: 0 40px;
}

.pt0 {
  padding-top: 0;
}

.pt3 {
  padding-top: 5px !important;
}

.pt5 {
  padding-top: 5px;
}

.pt7 {
  padding-top: 7px;
}

.pt10 {
  padding-top: 10px;
}

.pt14 {
  padding-top: 14px;
}

.pt15 {
  padding-top: 15px;
}

.pt15imp {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px;
}

.pt22 {
  padding-top: 22px;
}

.pt24 {
  padding-top: 24px !important;
}

.pt25 {
  padding-top: 25px;
}

.pt30imp {
  padding-top: 30px !important;
}

.pt30 {
  padding-top: 38px;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.plt8 {
  padding-left: 8px !important;
}

.pt7imp {
  padding-top: 7px !important;
}

.plt20 {
  padding-left: 20px !important;
}

.plt30 {
  padding-left: 30px !important;
}

.pl0 {
  padding-left: 0;
}

.pl0imp {
  padding-left: 0 !important;
}

.pl5 {
  padding-left: 5px;
}

.pl6 {
  padding-left: 6px;
}

.pl10 {
  padding-left: 10px;
}

.pl11 {
  padding-left: 11px;
}

.pl15 {
  padding-left: 15px;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl27imp {
  padding-left: 27px !important;
}

.pl30 {
  padding-left: 30px;
}

.pl32 {
  padding-left: 32px;
}

.pl33 {
  padding-left: 33px;
}

.pl35 {
  padding-left: 35px;
}

.pl40 {
  padding-left: 40px;
}

.pl95 {
  padding-left: 95px;
}

.pl98 {
  padding-left: 98px;
}

.pl60 {
  padding-left: 60px;
}

.pr0 {
  padding-right: 0;
}

.pr0imp {
  padding-right: 0 !important;
}

.pr4 {
  padding-right: 4px;
}

.pr5 {
  padding-right: 5px;
}

.pr7imp {
  padding-right: 7px !important;
}

.pr10 {
  padding-right: 10px;
}

.pr13 {
  padding-right: 13px;
}

.pr15 {
  padding-right: 15px;
}

.pr15 {
  padding-right: 15px !important;
}

.pr16 {
  padding-right: 16px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

.pr25imp {
  padding-right: 25px !important;
}

.pr27imp {
  padding-right: 27px !important;
}

.pr30 {
  padding-right: 30px;
}

.pr35 {
  padding-right: 35px;
}

.pr40 {
  padding-right: 40px;
}

.prt10 {
  padding-right: 10px !important;
}

.prt20 {
  padding-right: 20px !important;
}

.prt25 {
  padding-right: 25px !important;
}

.pb0 {
  padding-bottom: 0;
}

.pb0imp {
  padding-bottom: 0 !important;
}

.pb4 {
  padding-bottom: 4px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.phv20 {
  padding: 20px 40px !important;
}

.mbt70 {
  margin-bottom: 70px !important;
}

.mbt44 {
  margin-bottom: 44px !important;
}

.pbt0 {
  padding-bottom: 0 !important;
}

.pbt2 {
  padding-bottom: 2px !important;
}

.pbt4 {
  padding-bottom: 4px !important;
}

.pbt5 {
  padding-bottom: 5px !important;
}

.pbt10 {
  padding-bottom: 10px !important;
}

.pbt15 {
  padding-bottom: 15px !important;
}

.pbt18 {
  padding-bottom: 18px !important;
}

.pbt20 {
  padding-bottom: 20px !important;
}

.pbt25 {
  padding-bottom: 25px !important;
}

.pbt31 {
  padding-bottom: 31px !important;
}

.pbt40 {
  padding-bottom: 40px !important;
}

.pbt50 {
  padding-bottom: 50px !important;
}

.pbt60 {
  padding-bottom: 60px !important;
}

.mbt10 {
  margin-bottom: 10px !important;
}

.mr66i {
  margin-right: 66px !important;
}

.mr23i {
  margin-right: 23px !important;
}

.mr25i {
  margin-right: 25px !important;
}

.pl12p {
  margin-left: 12% !important;
}

.pr9p {
  padding-right: 9% !important;
}

.pr5p {
  padding-right: 5%;
}

.ml7p {
  margin-left: 7%;
}

.mb2p {
  margin-bottom: 2%;
}

.pb3p {
  padding-bottom: 3%;
}

.pb12p {
  padding-bottom: 12%;
}

.pb2p {
  padding-bottom: 2%;
}

/************************************************************************************************************** */

/* ----------------------	MARGIN AND PADDING UNITS ----------------------	*/

/************************************************************************************************************** */

.mlt40 {
  position: relative;
  margin-left: 40px;
}

.mlt70 {
  position: relative;
  margin-left: 70px;
}

.mltn20 {
  position: relative;
  margin-left: -20px;
}

.mlt0 {
  position: relative;
  margin-left: 0 !important;
}

.mlt3p {
  position: relative;
  margin-left: 3% !important;
}

.mlt2 {
  position: relative;
  margin-left: 2px !important;
}

.mlt3 {
  position: relative;
  margin-left: 3px !important;
}

.mlt5 {
  position: relative;
  margin-left: 5px !important;
}

.mlt8 {
  position: relative;
  margin-left: 8px !important;
}

.mlt10 {
  position: relative;
  margin-left: 10px !important;
}

.mlt11 {
  position: relative;
  margin-left: 11px !important;
}

.mlt15 {
  position: relative;
  margin-left: 15px !important;
}

.mlt12 {
  position: relative;
  margin-left: 12px !important;
}

.mlt20 {
  position: relative;
  margin-left: 20px !important;
}

.mlt22 {
  position: relative;
  margin-left: 22px !important;
}

.mlt25 {
  position: relative;
  margin-left: 25px !important;
}

.mlt27 {
  position: relative;
  margin-left: 27px !important;
}

.mlt30 {
  position: relative;
  margin-left: 30px !important;
}

.mlt30p {
  position: relative;
  margin-left: 30% !important;
}

.mlt35 {
  position: relative;
  margin-left: 35px !important;
}

.mlt37 {
  position: relative;
  margin-left: 37px !important;
}

.mlt40 {
  position: relative;
  margin-left: 40px !important;
}

.mlt50 {
  position: relative;
  margin-left: 50px;
}

.mlt85 {
  position: relative;
  margin-left: 85px !important;
}

.mlt100 {
  position: relative;
  margin-left: 100px !important;
}

.mlt6Per {
  margin-left: 6%;
}

.mlt14Per {
  margin-left: 14%;
}

.mrt0 {
  position: relative;
  margin-right: 0 !important;
}

.mrt2 {
  position: relative;
  margin-right: 2px !important;
}

.mrt5 {
  position: relative;
  margin-right: 5px !important;
}

.mrt7 {
  position: relative;
  margin-right: 7px !important;
}

.mrt10 {
  position: relative;
  margin-right: 10px !important;
}

.mrt200 {
  position: relative;
  margin-right: 200px !important;
}

.mrt15 {
  position: relative;
  margin-right: 15px !important;
}

.mrt20 {
  position: relative;
  margin-right: 20px !important;
}

.mrt30 {
  position: relative;
  margin-right: 30px !important;
}

.mrt45 {
  margin-right: 45px !important;
}

.mrt50 {
  position: relative;
  margin-right: 50px !important;
}

.mrt3p {
  position: relative;
  margin-right: 3% !important;
}

.mtop0 {
  position: relative;
  margin-top: 0 !important;
}

.mtop3 {
  position: relative;
  margin-top: 3px !important;
}

.mtop5 {
  position: relative;
  margin-top: 5px !important;
}

.mtop8 {
  position: relative;
  margin-top: 8px !important;
}

.mtop12 {
  position: relative;
  margin-top: 12px !important;
}

.mtop13 {
  position: relative;
  margin-top: 13px !important;
}

.mtop28 {
  position: relative;
  margin-top: 28px !important;
}

.mtop30 {
  position: relative;
  margin-top: 30px !important;
}

.mtop32 {
  position: relative;
  margin-top: 32px !important;
}

.mtop34 {
  position: relative;
  margin-top: 34px !important;
}

.mtop35 {
  position: relative;
  margin-top: 35px !important;
}

.mtop10 {
  position: relative;
  margin-top: 10px !important;
}

.mtop14 {
  position: relative;
  margin-top: 14px !important;
}

.mtop15 {
  position: relative;
  margin-top: 15px !important;
}

.mtop18 {
  position: relative;
  margin-top: 18px !important;
}

.mtop20 {
  position: relative;
  /* margin-top:5px !important; */
}

.mtop40 {
  position: relative;
  margin-top: 40px !important;
}

.mtop44 {
  margin-top: 44px;
}

.mtop50 {
  position: relative;
  margin-top: 50px !important;
}

.mtop65 {
  position: relative;
  margin-top: 65px !important;
}

.mtop70 {
  position: relative;
  margin-top: 70px !important;
}

.mtop100 {
  position: relative;
  margin-top: 100px !important;
}

.prt0 {
  position: relative;
  padding-right: 0 !important;
}

.prt3 {
  position: relative;
  padding-right: 3px !important;
}

.plt8 {
  position: relative;
  left: 8px !important;
}

.plt0 {
  position: relative;
  padding-left: 0 !important;
}

.ptop1 {
  position: relative;
  top: 1px !important;
}

.ptop2 {
  position: relative;
  top: 2px !important;
}

.ptop3 {
  position: relative;
  top: 3px !important;
}

.ptop4 {
  position: relative;
  top: 4px !important;
}

.ptop5 {
  position: relative;
  top: 5px !important;
}

.ptop6 {
  position: relative;
  top: 6px !important;
}

.ptop8 {
  position: relative;
  top: 8px !important;
}

.ptop9 {
  position: relative;
  top: 9px !important;
}

.ptop10 {
  position: relative;
  top: 10px !important;
}

.ptop12 {
  position: relative;
  top: 12px !important;
}

.ptop15 {
  position: relative;
  top: 15px !important;
}

.ptop17 {
  position: relative;
  top: 17px !important;
}

.ptop19 {
  position: relative;
  top: 19px !important;
}

.ptop20 {
  position: relative;
  top: 20px !important;
}

.ptop22 {
  position: relative;
  top: 22px !important;
}

.ptop25 {
  position: relative;
  top: 25px !important;
}

.ptop38 {
  position: relative;
  top: 38px !important;
}

.ptop60 {
  position: relative;
  top: 60px !important;
}

.ptop45 {
  position: relative;
  top: 45px !important;
}

.pbot9 {
  position: relative;
  bottom: 9px !important;
}

.pbot25 {
  position: relative;
  bottom: 25px !important;
}

.ptp20 {
  position: relative;
  padding-top: 20px !important;
}

.ptp25 {
  position: relative;
  padding-top: 25px !important;
}

.plt10 {
  position: relative;
  padding-left: 10px !important;
}

.plt15 {
  position: relative;
  padding-left: 15px !important;
}

.tminus2 {
  position: relative;
  top: -2px;
}

.lineHeight5 {
  line-height: 5rem !important;
}

.lh14p {
  line-height: 18px;
}

.ptp0 {
  position: relative;
  top: 0;
  padding-top: 0;
}

.inputText {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
       appearance: textfield !important;
  padding: 2px !important;
  background-color: white !important;
  border: 1px solid #ddd !important;
  font: 13.3333px Arial !important;
  text-rendering: auto !important;
  color: initial !important;
  letter-spacing: normal !important;
  word-spacing: normal !important;
  text-transform: none !important;
  text-indent: 0 !important;
  text-shadow: none !important;
  display: inline-block !important;
  text-align: start !important;
  height: 25px !important;
  width: 98.5% !important;
}

.prt5 {
  position: relative !important;
  top: 5px !important;
}

.border1 {
  border: 1px solid #ddd !important;
}

.border2 {
  border: 2px solid !important;
}

.plt22 {
  position: relative;
  padding-left: 22px !important;
}

.plt24 {
  position: relative;
  padding-left: 24px !important;
}

.plt25 {
  position: relative;
  padding-left: 25px !important;
}

.plt50 {
  position: relative;
  padding-left: 50px !important;
}

.plt150 {
  position: relative;
  padding-left: 150px !important;
}

.plt170 {
  position: relative;
  padding-left: 170px !important;
}

.l40 {
  left: 40px;
}

.pleft4 {
  position: relative;
  left: 5px;
  top: 2px;
}

.preline-wrap {
  white-space: pre-line !important;
}

.pt8i {
  padding-top: 8px !important;
}

.h100p {
  height: 100% !important;
}

.h300 {
  height: 300px;
}

.mh300 {
  min-height: 300px;
}

.h3p {
  height: 3px;
}

.h5p {
  height: 5px;
}

.h10p {
  height: 10px;
}

.h15p {
  height: 15px;
}

.mauto {
  margin: auto !important;
}

.border0 {
  border-bottom: none !important;
}

.right30 {
  right: 30px;
}

.mptpbt0 {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.ellipsis,
.ellipsis span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

td.listMainText .ellipsis span {
  max-width: 200px;
}

.mw200 {
  max-width: 200px;
}

.mw85p {
  max-width: 85%;
}

.mw250 {
  max-width: 250px;
}

.mw150 {
  max-width: 150px !important;
}

.plr3p {
  padding: 0 3.5%;
}

.plr5p {
  padding: 0 5%;
}

.plr8p {
  padding: 0 8.5%;
}

.halfOpacity {
  opacity: 0.5 !important;
}

.letsp1p {
  letter-spacing: 1px;
}

.letsp2p {
  letter-spacing: 2px;
}

.letsp3p {
  letter-spacing: 3px;
}

.actDateLbl {
  color: #163043;
  font-family: "Roboto Slab Bold";
  width: 100px;
  height: 24px;
  border-radius: 63px !important;
  padding: 4px 20px;
}

.actLine {
  box-sizing: border-box;
  width: 2px;
  height: 660px;
  border: 1px solid #d9dfe0;
}

.actImage {
  max-width: 50px;
  max-height: 50px;
}

.actProfile {
  background: url("../images/Cover.png") no-repeat center;
  background-size: cover;
  height: 97px;
  border-radius: 3px;
}

.w25p {
  width: 25%;
}

.w50p {
  width: 50%;
}

.w50pi {
  width: 50% !important;
}

.w75p {
  width: 75%;
}

.w75 {
  width: 75px;
}

.w80p {
  width: 80%;
}

.w100p {
  width: 100% !important;
}

.mw20p {
  min-width: 20%;
}

.submittedCircle {
  border-radius: 100%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #d9dfe0;
}

.inProgressCircle {
  background-color: #f5c727;
}

.textCenter {
  text-align: center;
  margin-right: 26px;
}

.textLeft {
  text-align: left;
}

.widthM100p {
  width: calc(100% - 100px) !important;
}

.activityTime {
  color: #acacac;
}

.activityFont {
  font-family: Montserrat;
  font-weight: bold;
  color: #43494c;
  line-height: 14px;
}

.w100Imp {
  width: 100% !important;
}

/* karthick */

.locationprofile {
  font-size: 10px;
  margin-bottom: 9px;
  margin-right: 20px;
  font-weight: bold;
  color: #43494c;
}

.locationprofileSideNav {
  font-size: 11px;
  font-weight: bold;
  line-height: 31px;
  color: #43494c;
}

hr.style1 {
  border-top: 1px solid #8c8b8b;
}

.checkBoxBgColor {
  background-color: #153c5a;
}

.checkBoxContentStyle {
  font-size: 11px;
  padding-bottom: 6px;
  font-family: Montserrat;
  letter-spacing: 1px;
  color: #fff;
}

md-checkbox.greenCheckbox .md-icon {
  border-color: #2bb29a !important;
}

md-checkbox.yellowCheckbox .md-icon {
  border-color: #f5c727 !important;
}

md-checkbox.redCheckbox .md-icon {
  border-color: #e34644 !important;
}

md-checkbox.whiteCheckbox .md-icon {
  border-color: #c5c2c3 !important;
}

md-checkbox.greenCheckbox.md-checked .md-icon {
  background-color: #2bb29a !important;
}

md-checkbox.yellowCheckbox.md-checked .md-icon {
  background-color: #f5c727 !important;
}

md-checkbox.redCheckbox.md-checked .md-icon {
  background-color: #e34644 !important;
}

md-checkbox.whiteCheckbox.md-checked .md-icon {
  background-color: #c5c2c3 !important;
}

.md-button .md-raised:not([disabled]) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.pr13l6 {
  padding: 0 13px 0 6px;
}

.md-button .md-raised:not([disabled]) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.formBottom {
  background-color: #f5f5f5;
  height: 74px;
}

.nextButton {
  background-color: #f5c727 !important;
  float: right;
  border-radius: 20px;
  margin-top: 20px;
}

.txtStart {
  text-align: start;
}

.width605px {
  width: 605px;
}

.removeSelection {
  margin-right: 10px;
  margin-top: 10px;
  margin-left: -22px;
  width: 10px;
  font-size: 12px !important;
  font-weight: 900 !important;
  color: #747c80;
}

.dropdownSelection {
  margin-top: 0;
  margin-bottom: 10px;
}

.lytWhiteBg {
  background: #f4f8f9;
}

#investigationListId {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 2px 0 #ccc;
}

.prospectProfileBack:focus {
  border: none !important;
  outline: none;
}

.h60p {
  height: 60px;
}

.allActive {
  border: 1px solid #44bf82;
}

.thirtyOpen {
  border: 1px solid #e94949;
}

.fifteenOpen {
  border: 1px solid #f5c727;
}

.noOutLine {
  outline: none !important;
}

.overflowHidden {
  overflow: hidden !important;
}

.cursorText {
  cursor: text;
}

.prospects {
  color: #fff !important;
  opacity: 0.5;
  outline: none;
  padding: 12px 24px 0 24px;
}

.activeDiv {
  opacity: 1 !important;
}

.prospects .carett {
  background: none !important;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  margin-left: 45px;
}

.mw165 {
  max-width: 165px !important;
}

.noVScroll {
  overflow-y: hidden;
}

.overYAuto {
  overflow-y: auto !important;
}

.hideIcon md-datepicker .md-datepicker-button.md-icon-button {
  display: none !important;
}

.hideIcon .md-datepicker-input-container {
  margin-left: 0;
}
.md-calendar-date-selection-indicator {
  text-transform: capitalize !important;
}

.md-calendar-date.md-calendar-date-disabled {
  text-transform: capitalize !important;
}

.md-calendar-month-label span {
  text-transform: capitalize !important;
}

.md-datepicker-input-container {
  text-transform: capitalize !important;
}

.dateFilter {
  color: #7c8487;
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  line-height: 15px;
}

.dateFilter:hover {
  color: #193549;
}

.blueColor {
  color: #193549 !important;
}

.popup {
  max-width: 100%;
  max-height: 80%;
}

.confirmAssign {
  font-size: 24px;
  text-align: center;
}

.montserrat {
  font-family: Montserrat;
}

.fwbold {
  font-weight: bold;
}

.lh13p {
  line-height: 13px;
}

.brad31p {
  border-radius: 31px;
}

.userActiveCount {
  color: #43494c;
  width: 125px;
  height: 24px;
  border: 1px solid #f5c727;
  background-color: rgba(245, 199, 39, 0);
}

.camelCase {
  text-transform: capitalize !important;
}

/*Sharmila*/

.invesForm {
  background-color: #ffffff;
  height: 100%;
}

.investigationFormHeader {
  font-family: "Roboto Slab Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 1px;
  color: #43494c;
}

.investigationFormContent {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.w550 {
  max-width: 550px;
}

.investigationAttachmentIcon {
  padding-right: 10px;
  color: #000000;
}

.attachicon {
  font-size: 20px;
}

.investigationAttachmentFile {
  width: 280px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #919ca1;
  padding-top: 3px;
}

.investigationAttachmentCancel {
  font-size: 20px;
  color: #ff0000;
}

.attachementAddIconspan {
  color: #2bb29a;
  padding-right: 4px;
}

.attachAddIcon {
  font-size: 13px;
  font-weight: bold;
}

.attachmentAddText {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #2bb29a;
}

.sectionBC {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

/* sharmila */

/*start of md-accordian style*/

.md-accordion .dataContent {
  height: 0;
  overflow: hidden;
  transition: 0.3s all ease-out;
}

.md-accordion .activeContent {
  height: auto;
  padding: 13px;
  display: block;
  overflow: inherit;
}

/*end of md-accordian style*/

.accordianNameHolder {
  font-size: 14px;
  font-family: Montserrat;
  color: #2bb29a;
  padding: 5px 20px;
  border: 1px solid #2bb29a;
  border-radius: 15px;
  margin-left: 10px;
  font-weight: 700;
}

.lineSeparator {
  border-bottom: 1px solid #2bb29a;
  font-size: 2px;
  position: relative;
  height: 1px;
}

.accordianHolder {
  margin-top: 40px;
  margin-left: 15px;
}

.accordianCircle {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 53%;
  border: 1px solid #2bb29a;
}

.accordianCircleContent {
  margin: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #2bb29a;
}

.mw10p {
  min-width: 10px;
}

.accordianContentTransform {
  transform: rotate(180deg);
}

.trashHolder {
  margin-top: 40px;
  margin-left: 25px;
}

.trashInterview {
  font-size: 20px;
  color: #e94949;
}

.activeSectionForm {
  background-color: #13517d;
  opacity: 1 !important;
}

.userprospectHeader {
  min-height: 610px;
  width: 320px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.w148 {
  width: 148px !important;
}

.usersProfileHeight {
  min-height: 620px !important;
}

.pdUserLeft {
  margin-left: 15.6% !important;
}

.pl4p {
  margin-left: 4%;
  padding-left: 0 !important;
}

.listBorder {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 2px 0 #ccc;
}

.mw125px {
  min-width: 125px;
}

.mw20pp {
  min-width: 20% !important;
}

.calcH35 {
  overflow-y: auto;
  height: calc(100% - 20px);
}

.sideNavScroll {
  background-color: #ffffff;
  padding-left: 5%;
  position: relative;
  overflow: auto;
  width: 100%;
  top: 69px;
  z-index: 3;
}

/* ADD INVESTIGATOR POPUP */

.mTB {
  float: right;
  margin-top: -37px;
  margin-bottom: -47px;
}

.fltRt {
  float: right;
}

.fab {
  background-color: #f5c727 !important;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.22) !important;
}

.pd50 {
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 10px;
}

.saveOrClose {
  width: 90px;
  height: 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 53px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  color: #ffffff;
  margin-left: 25px !important;
}

.md-checkbox-enabled[selected] .md-icon {
  background-color: rgb(255, 196, 64);
}

md-select-menu md-content md-option[selected] {
  color: rgb(255, 196, 64);
}

.addEditPopup {
  max-width: 100%;
  max-height: 80%;
  width: 720px;
}

.h391 {
  height: 391px;
}

.popupMBL {
  min-width: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}

.borderNone {
  border: none !important;
}

.border1p {
  border: 1px solid #c9cbce;
}

.popupTle {
  font-family: "Roboto Slab";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
  letter-spacing: 2px;
  color: #ffffff;
}

.popupHr {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
  padding-top: 15px;
}

.popupFont {
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  color: #43494c !important;
}

.popupCmt {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px !important;
  color: #43494c !important;
}

.popupLbl {
  font-family: Montserrat;
  font-size: 15px;
  color: #919ca1;
}

.required:after {
  color: #919ca1;
  content: "*";
  padding-left: 5px;
}

button.saveOrClose.caps.color-red.md-button.ng-scope.md-ink-ripple.md-focused {
  background-color: #e94949;
}

button.saveOrClose.caps.color-yellow.md-button.ng-scope.md-ink-ripple.md-focused {
  background-color: #f5c727;
}

button.saveOrClose.caps.color-red.md-button.ng-scope.md-ink-ripple {
  background-color: #e94949;
}

button.saveOrClose.caps.color-yellow.md-button.ng-scope.md-ink-ripple {
  background-color: #f5c727;
}

.w95 {
  width: 95% !important;
}

.w90 {
  width: 90%;
}

.w93 {
  width: 93%;
}

.s25 {
  font-size: 25px;
  color: #21364b;
}

.userprospectHeader {
  height: 610px;
  width: 320px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.usersProfileHeight {
  height: 620px !important;
}

.errorMsg {
  color: #dd2c00 !important;
}

.locationError,
.locationError:hover {
  color: #1e90ff !important;
}

.locationError:hover {
  text-decoration: underline !important;
  text-decoration-color: #1e90ff !important;
}

.mtMsg {
  margin-top: -3px !important;
}

.borderError {
  border-color: rgb(221, 44, 0) !important;
}

.s18 {
  font-size: 18px;
  color: #fff;
}

/* EDIT INVESTIGATOR POPUP */

.mR3 {
  margin-right: 3%;
}

.mR7 {
  margin-right: 7%;
}

@media only screen and (max-width: 1024px) {
  .UserProfileInfo {
    margin-left: 6%;
  }

  .pb3p {
    padding-bottom: 10% !important;
  }

  .nextLabelFont {
    font-size: 13px;
    margin-top: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .nextLabelFont {
    font-size: 12px;
    margin-top: 10px;
  }
}

/* INVESTIGATION FORM SIDE NAVIGATION*/

.menuPosition {
  padding-bottom: 18px;
  padding-top: 18px;
  padding-right: 25px;
  font-family: Montserrat;
  font-size: 12px;
}

.navHeading {
  padding: 15px;
  color: #ffffff;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -15px;
}

.mw56px {
  min-width: 56px !important;
}

.pleftNg24 {
  position: relative;
  left: -24px;
}

.approveAlt {
  width: 155px;
  height: 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 53px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  color: #ffffff;
  margin-left: 25px !important;
}

.approvReq {
  margin: 5px 5px -25px 5px !important;
}

.tn20 {
  position: relative;
  top: -20px;
}

.reqProfile {
  background: url(../images/Cover.png) no-repeat center;
  background-size: cover;
  /*   height: 150px; */
  border-radius: 3px;
}

.reqProfileEntity {
  background: url(../images/Cover.png) no-repeat center;
  background-size: cover;
  min-height: 150px;
  border-radius: 3px;
}

.reqProfilexs {
  background: url(../images/Cover.png) no-repeat center;
  background-size: cover;
  /*  height: 250px; */
  border-radius: 3px;
}

.entityProfilexs {
  background: url(../images/Cover.png) no-repeat center;
  background-size: cover;
  height: 150px;
  border-radius: 3px;
}

.l55 {
  position: relative;
  left: 120px;
  top: 20px;
}

.mtop21 {
  position: relative;
  margin-top: 23px !important;
  margin-left: -75px;
}

.mtop16 {
  position: relative;
  margin-top: 16px !important;
  left: -45px;
}

.mltopn10 {
  position: relative;
  margin-top: 10px !important;
  margin-left: -10px;
}

.ln90 {
  position: relative;
  left: -60px;
}

.ln60 {
  position: relative;
  left: 0;
  top: 10px;
}

.autocompletedemoCustomTemplate md-autocomplete-wrap:after {
  font-family: "Material Icons";
  content: "\E8B6";
  /*E5C5*/
  line-height: 1;
  padding-top: 6px;
  font-weight: 600;
  font-size: 24px;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.2);
}

.searchFilter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.affliateListOnsiteAutocomplete {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 2px;
  font-family: Lato;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.1);
}

.h35px {
  height: 35px;
}

/* Muthukumari */

.h20 {
  height: 20px;
}

.h40 {
  height: 40px;
}

.mh50 {
  min-height: 70px;
}

.h19 {
  height: 19px;
}

.buttonColor {
  color: #43494c;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize10 {
  font-size: 10px;
  color: #96a0a5;
}

.normalFont {
  font-weight: normal;
}

.reqEntPopupM {
  max-width: 100% !important;
  height: 100%;
  max-height: 100% !important;
}

.reqEntPopupD {
  max-width: 100%;
  width: 665px;
  max-height: 100%;
}

.pad8px {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}

.pad8lr {
  padding-left: 8px;
  padding-right: 8px;
}

.pr8 {
  padding-right: 8px;
}

.pt21px {
  padding-top: 21px !important;
}

#approvEntityBtn {
  background-color: #f5c727;
  box-shadow: none;
  color: #43494c;
  text-align: left;
  font-weight: bold;
}

#requestEntityBtn {
  background-color: #f5c727;
  box-shadow: none;
  color: #43494c;
  text-align: left;
  font-weight: bold;
}

#approvLocBtn {
  background-color: #f5c727;
  box-shadow: none;
  color: #43494c;
  text-align: left;
  font-weight: bold;
}

#requestLocBtn {
  background-color: #f5c727;
  box-shadow: none;
  color: #43494c;
  text-align: left;
  font-weight: bold;
}

.downArw {
  /* position: relative; */
  margin-left: 30px;
  margin-top: 7px;
}

.m20px {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.top7px {
  position: relative;
  top: 7px;
  left: 10px;
}

.cmnt {
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 50px !important;
}

.cmtbox {
  min-height: 80px !important;
  border: 1px solid grey !important;
}

.width530px {
  width: 530px;
}

.width350px {
  width: 350px;
}

.mTN50 {
  margin-top: -3px;
}

.librarySelect {
  margin-right: 10px;
  margin-top: 10px;
  margin-left: -22px;
  width: 10px;
  font-size: 12px !important;
  font-weight: 900 !important;
  color: #747c80;
}

.left-drop-library-search {
  padding-bottom: 8px !important;
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  color: #43494c !important;
  border-bottom: 1px solid #c9cbce !important;
  float: none;
  cursor: text;
}

.libLocation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.w400p {
  width: 400px !important;
}

.w485 {
  width: 485px;
}

.w270 {
  width: 270px;
}

.locationPopInputSty {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 40px;
  width: 190px;
}

#addlocationRequestFormId .filterSubHeading {
  font-family: Montserrat !important;
  font-size: 15px !important;
  color: #919ca1 !important;
}

#requestEntityFormId .filterSubHeading {
  font-family: Montserrat !important;
  font-size: 15px !important;
  color: #919ca1 !important;
}

.entyl105 {
  position: relative;
  left: 105px;
  top: 20px;
}

.entytn15 {
  position: relative;
  margin-top: 7px !important;
  left: -15px;
}

.l100px {
  position: relative;
  left: -100px;
}

.entynl90 {
  position: relative;
  margin-top: 7px !important;
  left: -90px;
}

.entityl0px {
  position: relative;
  left: 0;
  top: 10px;
}

.locationListRow {
  background: #fff url("../images/Location-Inactive.png") no-repeat 40px 15px;
}

.locationListRow:hover {
  background: #fafafa url("../images/Location-Active.png") no-repeat 40px 15px;
}

.locationApplyBtnSty {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.createPopup {
  width: 400px;
  height: auto;
  max-height: calc(100% - 60px);
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.hide {
  display: none;
}

.reqEntityNameSearch {
  opacity: 1;
  padding-top: 3px;
}

.greyBorder {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.resizeNone {
  resize: none;
  font-family: Montserrat !important;
  font-size: 13px;
  color: #43494c !important;
  /* #2BB29A */
  font-weight: 900;
}

.redTxt {
  color: red;
}

.hyt60p {
  height: 60px !important;
}

.showBlk {
  display: block;
}

.navHead {
  background: url("../images/Cover.png");
  background-size: cover;
}

.navDateandcountry {
  font-size: 12px;
}

.formType {
  font-family: Montserrat;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  opacity: 0.7;
}

md-content.md-default-theme,
md-content {
  color: #8d8d8d;
}

.textDecorationNoneImportant {
  text-decoration: none !important;
}

.progressHolder {
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
}

.uploadProgress ._md-dashed {
  background-color: rgba(0, 112, 65, 0.3);
  height: 5px;
}

.uploadProgress ._md-bar2 {
  background-color: #007041 !important;
}

.w400 {
  width: 400px;
}

.progressbarWidth {
  max-width: 47%;
  width: 100%;
}

.progressClose {
  margin-top: 10px;
  margin-left: 12px;
}

.disableEditor {
  width: 100%;
  max-height: 50%;
  padding-left: 7px;
  padding-top: 9px;
  padding-right: 7px;
  border: 1px solid #919ca1;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: normal;
}

.ph8v16 {
  padding: 8px 16px;
}

.pt0imp {
  padding-top: 0 !important;
}

.lytGreyTxt {
  color: #c9cbce !important;
}

.lytGreyBackgrnd {
  background-color: #c9cbce !important;
}

.statCircle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 7px;
  margin-top: 5px;
}

.yellowBg {
  background-color: #f5c727;
}

.redBg {
  background-color: red;
}

.lytGreyBg {
  background-color: #d9dfe0;
}

.grnBg {
  background-color: rgb(4, 167, 119);
}

.p0imp {
  padding: 0 !important;
}

.w561 {
  width: 561px;
}

.w110Imp {
  width: 110px !important;
}

.tes {
  padding: 0 0 0 6px;
}

.addCmntText {
  height: 18px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #2bb29a;
  cursor: pointer;
}

.completeAccordHeadLarge {
  position: relative;
  left: 35%;
  top: 18px;
  background: #fff;
  border: 2px solid #c9cbce;
  width: 235px;
}

.completeAccordHeadSmall {
  position: relative;
  /* left: 20%; */
  top: 18px;
  background: #fff;
  border: 2px solid #c9cbce;
  width: 86%;
  left: 8%;
}

.compeleteProfAccord hr {
  border: 1px solid #c9cbce;
}

.profileProspectReportLabel {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  color: #43494c;
}

.lh19 {
  line-height: 19px;
}

.lh26 {
  line-height: 26px;
}

.clrBth {
  clear: both;
}

.mlr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.brder50px {
  border-radius: 50px;
}

.signOutAlign {
  position: relative !important;
  bottom: 5px !important;
  padding: 3px 12px 3px 0 !important;
}

.signOutIcon {
  position: relative !important;
  top: 6px !important;
}

.darkGreyBg {
  background-color: #6e6e6e !important;
}

.textAlignCenter {
  text-align: center;
}

span {
  outline: none;
}

.h60 {
  height: 60px;
}

.mh60 {
  min-height: 60px;
}

.resetDirective {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
}

.signInColor {
  color: #231f20;
}

#loginButton a:hover {
  color: #231f20;
}

.mt140 {
  margin-top: 140px;
}

.h375 {
  height: 375px;
}

.w150 {
  width: 150px;
}

.disableText {
  color: #919ca1 !important;
}

.disableBackground {
  background-color: #d9dfe0;
}

.h30 {
  height: 30px;
}

.pb12 {
  position: relative;
  bottom: 12px;
}

.pAtop50 {
  position: absolute;
  top: 50px;
}

#unauthorizedpage {
  width: 380px;
  height: 346px;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  margin-top: 90px;
}

.accessDeniedContent {
  padding-top: 22px;
  padding-bottom: 32px;
  width: 100%;
  height: 19px;
  font-family: Montserrat;
  font-size: 22px;
  text-align: center;
  line-height: 19px;
  letter-spacing: 2px;
  color: #ffffff;
  margin: 0 auto;
}

.accessDeniedMessage {
  padding-top: 22px;
  padding-bottom: 45px;
  font-size: 12px;
  width: 100%;
  height: 19px;
  font-family: Montserrat;
  text-align: center;
  line-height: 19px;
  letter-spacing: 2px;
  color: #ffffff;
  margin: 0 auto;
}

.w610 {
  width: 610px;
}

.w500 {
  width: 500px !important;
}

.circleRequest {
  width: 35px;
  height: 35px;
  border-radius: 25px;
}

.odd {
  background-color: #43494c;
}

.even {
  background-color: #696d70;
}

.circleLetter {
  top: 10px;
  left: 6px;
  position: relative;
  font-size: 10px;
}

.mH20p {
  max-height: 20px !important;
}

.roboFont {
  font-family: "Roboto Slab Bold";
  font-weight: bold;
  color: #43494c;
  font-size: 10px;
}

.mt27imp {
  margin-top: 27px !important;
}

.copySelectedDisableFont {
  color: #919ca1;
}

.copySelectedDisableBg {
  background-color: #d9dfe0;
}

.lineHeight25 {
  line-height: 25px;
}

.pt2P {
  padding-top: 2%;
}

.defaultBlack {
  color: #43494c !important;
}

.mt7P {
  margin-top: 7%;
}

.z5imp {
  z-index: 5 !important;
}

.w380 {
  width: 380px;
}

.w320 {
  width: 320px;
}

.w65 {
  width: 65px;
}

.opacity4 {
  opacity: 0.4;
}

.letterSpacing1 {
  letter-spacing: 1px;
}

.mt60 {
  margin-top: 60px;
}

@media only screen and (max-width: 960px) and (min-width: 601px) {
  .mt50TAB {
    margin-top: 50px !important;
  }

  .mt55TAB {
    margin-top: 55px !important;
  }

  .h150 {
    height: 190px !important;
  }

  .btnWidth {
    width: 260px !important;
  }
}

.SideCommentsDiv {
  min-width: 15pc;
  overflow-x: hidden;
}

/* Side Comments CSS */

.sideCommentsPosition {
  margin-right: -35px;
}

.squareBkround {
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 8px;
  color: #183348;
  margin-top: -20px;
}

.emptySideComment {
  color: #183348 !important;
}

.squareBkround .popover-content {
  padding: 0;
}

.squareBkround .popover.right {
  margin-left: 30px;
}

.commentedBox {
  background-color: #ffffff;
  border-radius: 2px;
  margin-top: 25px;
  margin-left: 5px;
}

.comment {
  margin: 3px;
}

.CommentedByName {
  font-weight: bolder;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 19px;
  color: #43494c;
}

.CommentedDate {
  font-size: xx-small;
  padding-bottom: 5px;
}

.replyArea {
  padding-top: 10px;
}

.replyText {
  border-radius: 5px;
}

.replyButton {
  height: 18px;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 18px;
  color: #2bb29a;
  margin-left: -5px;
  cursor: pointer;
}

.postButton {
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  color: #2bb29a;
  cursor: pointer;
}

.cancelButton {
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  color: #c0c0c0;
  cursor: pointer;
}

.ifEdited {
  color: #ff8282;
  font-size: x-small;
}

.squareBkround .popover .arrow {
  top: 18px !important;
}

.mobileCommentButton {
  color: #c0c0c0 !important;
}

.showMoreOrLess {
  color: #2bb29a;
  font-size: 12px;
  text-decoration: underline;
}

.squareBkround .popover {
  z-index: 0 !important;
}

.greenColor {
  color: #2bb29a !important;
}

.height270p {
  height: 270px !important;
}

.mrm40p {
  margin-right: -40px;
}

.mrm10p {
  margin-right: -10px;
}

.height155p {
  height: 150px !important;
}

.greyColor {
  color: #bababa;
}

.mt6P {
  margin-top: 6%;
}

.nowrap {
  white-space: nowrap;
}

.rcActivity {
  font-size: 12px;
  line-height: 10px;
}

.mtm60p {
  margin-top: -60px;
}

.mtm40p {
  margin-top: -40px;
}

.mtm20p {
  margin-top: -20px;
}

.sort {
  background: url("../images/Sort.png") no-repeat 0 0;
}

.iconLeft {
  margin-left: 2%;
}

.colorBlack {
  color: #333 !important;
}

.mtm50p {
  margin-top: -50px;
}

.pl10per {
  padding-left: 10.5%;
}

@media only screen and (min-width: 960px) {
  .w285 {
    width: 285px !important;
  }
}

.h20Imp {
  height: 20px !important;
}

.pl9per {
  padding-left: 9%;
}

.notAllowed {
  cursor: not-allowed !important;
}

.formOpenButton {
  border-radius: 0 !important;
  width: 110px !important;
}

.reopenCircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 14px;
  color: white;
  line-height: 25px;
  text-align: center;
  background-color: #d93a37;
}

.reopenCompletedBg {
  background-color: #5a8770;
}

.popoverClass {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  color: #43494c;
}

.popoverPosition {
  width: 25px;
  height: 25px;
}

.p20imp {
  padding: 20px !important;
}

.formEntry {
  background: url("../images/Form-Entry-Flag.png") no-repeat 0 0;
}

.minhe40 {
  min-height: 40px;
}

.requestType {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
}

.requestTypeColor {
  color: rgba(72, 95, 111, 0.8);
}

.requestValueColor {
  color: rgba(116, 124, 128, 0.6);
}

.entityValue {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.formMenu {
  font-weight: 400;
  color: #43494c;
}

.btn-spinner:not(:required) {
  animation: three-quarters 1250ms infinite linear;
  border: 1px solid #fff;
  border-right-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  width: 14px;
  height: 14px;
}

.btn-spinner:not(:required) {
  margin-left: 0;
  opacity: 1;
  transition: 0.4s margin ease-out, 0.2s opacity ease-out;
}

.pdlr40 {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 10px;
}

.w780 {
  width: 780px !important;
}

.w60Pi {
  width: 60% !important;
}

.w190i {
  width: 190px !important;
}

.disable {
  pointer-events: none;
}

.redFlag {
  background: url("../images/Red-Flag.png") no-repeat 0 0;
  min-height: 24px;
}

.dnaPending {
	background: url("../images/dna_pending-9c6d03768e-56ee806f3a.png") no-repeat 0px 0px;
    min-height: 32px;
}
 
.dnaConducted {
	background: url("../images/dna_conducted-48a4f07cf6-eb0f1ba79a.png") no-repeat 0px 0px;
    min-height: 32px;
}

.tgRightHeaderHgt1 {
  height: 32px;
}

.popupTle1 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  line-height: 26px;
  letter-spacing: 2px;
  color: #ffffff;
  height: 31px;
}

.popupTle2 {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1px;
  color: #85949f;
}

.ptitle {
  padding: 3px 15px 15px 15px;
}

.importFamilyPopup {
  min-width: 29.5%;
  min-height: 80%;
}

.h10p {
  height: 10% !important;
}

.h67p {
  height: 85% !important;
}

.m20p {
  margin: 19px 25px !important;
}

.bold {
  font-weight: bold;
}

.shadowBottom {
  box-shadow: 0 8px 11px -2px #e4e4e4;
}

.noValueAlert {
  padding-top: 20px;
  font-weight: lighter;
  font-family: "lato";
  text-align: center;
  font-size: 20px;
}

.marginAuto {
  margin: auto !important;
}

.w468p {
  width: 468px;
}

.title {
  margin: 19px 25px 12px 25px !important;
}

.fontColorWhite {
  color: white;
}

.fontColorBlack {
  color: #43494c;
}

.fLeft {
  float: left !important;
}

.fRight {
  float: right !important;
}

.f30p {
  font-size: 20px;
}

.topAuto {
  top: auto !important;
}

.h40px {
  height: 40px !important;
}

.fontFamily {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.h46px {
  height: 46px !important;
}

.nextBtn1 {
  height: 40px;
  background-color: #f5c727;
  width: 180px;
  border-radius: 10px;
}

.heightInherit {
  height: inherit !important;
}

.pt10imp {
  padding-top: 10px !important;
}

.pl12per {
  padding-left: 12%;
}

.mt15imp {
  margin-top: 15px !important;
}

.h110 {
  height: 110px !important;
}

.h120 {
  height: 120px;
}

.h130 {
  height: 130px;
}

.h150 {
  height: 150px;
}

.h170 {
  height: 170px;
}

.importBtnMBL {
  white-space: normal;
  line-height: inherit;
}

.h80 {
  height: 80px !important;
}

.h90 {
  height: 90px;
}

.h100 {
  height: 100px;
}

.confirmBtnWid {
  width: 260px !important;
}

.h900p {
  height: 900px !important;
}

.maxw825p {
  max-width: 825px;
}

.s30 {
  font-size: 30px;
}

.p15 {
  padding: 15px 15px 15px 15px;
}

.m10i {
  margin: 10px !important;
}

.p10i {
  padding: 10px !important;
}

.imageContent {
  width: 215px;
  height: 220px;
  border: 8px solid white;
  display: inline-block;
}

.textRight {
  text-align: right;
}

.w5 {
  width: 5px;
}

.w95px {
  width: 95px;
}

.w100px {
  width: 100px;
}

.previewImage {
  align-content: center;
  opacity: 1;
  border: 2px solid grey;
}

.textOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .textOverflow:hover{
    overflow: visible;
    white-space: normal;
    height:auto;
} */
.imageDetails {
  height: 200px;
  width: 200px;
}

.manageAttachmentsTxt {
  letter-spacing: 2px;
  text-decoration: underline;
  height: 30px;
}

.viewDocumentBtn {
  font-size: 10px;
  position: absolute;
  top: 5%;
  right: 5px;
  width: 95px;
  height: 15px;
  background-color: #f2f2f2;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

@media only screen and (min-width: 1030px) {
  .imageContent:hover .viewDocumentBtn {
    display: block !important;
  }

  .imageContent:hover .previewImage {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 600px) {
  .s20mbl {
    font-size: 20px !important;
  }

  .w100pmbl {
    width: 100% !important;
  }

  .pr15mbl {
    padding-right: 15px;
  }

  .pt10mbl {
    padding-top: 10px !important;
  }
}

.containerPreview {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.checkmarkBg {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #2196f3;
  display: none;
}

.selectBorder {
  border: 8px solid #2196f3;
}

.checkmark {
  content: "";
  position: absolute;
  display: block;
}

.containerChecked {
  background-color: #2196f3;
}

.containerPreview .checkmark {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.deleteBtn {
  text-transform: none;
  min-width: 160px;
  font-size: 12px;
  background-color: #9b9b9b;
  height: 30px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-right: 20px;
  padding-top: 6px;
}

.deleteSelected {
  background-color: #e94949 !important;
}

.w120px {
  width: 120px;
}

.w205pximp {
  width: 215px !important;
}

.vAlignMiddle {
  vertical-align: middle;
}

.flexClass {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.heightAuto {
  height: auto !important;
}

.mr5per {
  margin-right: 5%;
}

.mLeft10 {
  margin: 0 0 0 10px !important;
}

.openformbtnmr {
  height: 40px;
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
  border-radius: 2px;
  width: 200px;
  margin-left: auto;
  margin-right: 10px;
}

.openformbtnmr .div {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  color: #43494c;
  padding: 12px 10px 12px 10px;
}

.redalert {
  background: url("../images/entity_alert.png") no-repeat 0 0;
  min-height: 100%;
  margin-right: -25px;
}

.mt20 {
  margin-top: 20px;
}

.mt110 {
  margin-top: 110px;
}

.mt60 {
  margin-top: 60px;
}

.mt55 {
  margin-top: 55px;
}

.listEntityIcon {
  height: 35px;
  margin-bottom: 5px;
}

.width1050px {
  width: 1050px !important;
}

.mln5 {
  margin-left: -5px;
}

.mln1 {
  margin-left: -1px;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 800px) {
  .openformbtnmr {
    height: 45px !important;
  }

  .openformbtnmr .div {
    line-height: 18px !important;
    padding: 10px 10px 12px 10px;
  }
}

.width260px {
  width: 260px;
}

.nextBtn2 {
  width: 158px;
  height: 40px;
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 53px;
}

.nextBtn2:not([disabled]):hover {
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.nextBtn2[disabled] {
  background-color: #f5c727;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.filterSubHeadingnew {
  color: #919CA1 !important;
}

.md-select-value {
	border-bottom-color: black !important;
}

h4.admin-heading {
	margin-top: -10px;
	margin-right: 12px;
}

.m1100 {
	margin-top: 1.7%;
	margin-right: 96px !important;
}

.m1200 {
	margin-top: 1.7% 10px;
	margin-bottom: -11px;
	margin-left: 52px !important;
}

.w1200 {
	margin-left: -22px !important;
	margin-right: -10px !important;
}

/* .datePosition{
	margin-top:1.5% 10px; 
	margin-bottom: 0px;
} */
.countryDropDown {
	margin-top: 1.7%;
	margin-right: 98px !important
}

.m1500 {
	margin-top: 4%;
	margin-right: 47px;
}

.m1600 {
	margin-top: 4%;
}

.m1700 {
	margin-top: 6px;
}

.m1800 {
	text-align: right;
	margin-left: -110px !important;
}

.m1900 {
	text-align: right;
}

.m2100 {
	margin-left: 17px;
}

.m2200 {
	margin-left: 0px !important;
	margin-bottom: -9px !important;
	margin-right: 1px !important;
}

.m400px {
	display: flex;
	justify-content: center;
}

.m300px {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: nowrap;
	justify-content: space-between;

}

.countryName {
	margin-top: -12px;
	margin-right: 10px;
	margin-left: 70px;
}

.countryNamedesign {
	margin-top: -12px;
	margin-left: 30px;
}

.m2121 {
	margin-left: 74px;
}

.mr100imp {
	margin-right: 311px !important;
}

.mr200imp {
	margin-left: 23px;
}

.ml21imp {
	margin-left: -183px !important;
}

.mlr100imp {
	margin-left: 112px !important;
}

.mr12000imp {
	margin-right: 6px !important;
}

.ml10imp {
	margin-left: -14px !important;
}

.w1212px {
	margin-top: -65px;
	margin-right: -34px;
}

.countryColor {
	color: #43494C;
	margin-left: -2px;
	font-size: 13px;
}

.checkboxColor md-checkbox:not(.md-checked) .md-icon {
	color: rgba(117, 117, 117, 0.87);
	box-sizing: border-box;
	transition: 240ms;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	border-width: 2px;
	border-style: solid;
	border-radius: 2px;
	margin-left: -1px;
}

.w20000 {
	margin-top: -8px;
}

.crossRemoveSelection {
	margin-right: 10px;
	margin-top: 6px;
	margin-left: -22px;
	width: 10px;
	font-size: 12px !important;
	font-weight: 900 !important;
	color: #747C80;
}

.checkTick md-checkbox.md-checked .md-icon:after {
	box-sizing: border-box;
	transform: rotate(45deg);
	position: absolute;
	left: -3.33333px;
	top: -7.77778px;
	display: table;
	width: 6.66667px;
	height: 13.33333px;
	border-width: 2px;
	border-style: solid;
	border-top: 0;
	border-left: 0;
	content: '';
}

.checkWidth md-checkbox.md-checked .md-icon {
	background-color: #F5C727;
	border-width: 10.5px;
}

.m13left {
	margin-left: 13px;
}

.adminHeader {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: bold;
	line-height: 19px;
	color: #43494C;
	margin-bottom: 10px;
	/* margin-left: -70px; */
  margin-left: 58px;
}

.checkAlign md-checkbox .md-icon {
	margin-top: 1px;
}

.sideFilter {
	height: 15px;
	font-family: Montserrat;
	font-size: 12px;
	line-height: 15px;
	color: #333;
	text-align: left;
	margin-left: 85px;
	margin-bottom: 15px;
  margin-top: 3px;
}

.m100wrap {
	margin-left: -15px;
}
.stickySearchCountry {
	max-height: 150px;
	overflow-y: auto;
	width: 100%;
}

.stickySearchProvince {
	max-height: 150px;
	overflow-y: auto;
	width: 100%;
}
.stickySearchCity {
	max-height: 150px;
	overflow-y: auto;
	width: 100%;
}
.stickySearchSector {
	max-height: 150px;
	overflow-y: auto;
	width: 100%;
}
.stickySearchField {
	height: 24px !important;
}
.selectionRemove{
  margin-right: 10px;
  margin-top: 10px;
  margin-left: -22px;
  width: 10px;
  font-size: 12px !important;
  font-weight: 900 !important;
  color: #747C80;
}

.dnadropdownSelection md-select{
margin : 2 !important;	
}

.mt1p {
	margin-top: 1px;
}

.fc100{
	color: #7c8487d1;
	font-family: 'Lato';
    font-size: 12.4px;
    font-weight: 600;
    line-height: 14px;
    margin-top: 3px;
    letter-spacing: 0.5px;
	margin-left: 2px;
}
.fc200{
	color: #7c8487d1;
	font-family: 'Lato';
    font-size: 12.4px;
    font-weight: 600;
    line-height: 14px;
    margin-top: 3px;
    letter-spacing: 0.5px;
	margin-bottom: 25px;
}
.mtop20{
	margin-top: 20px;
}
.arrowmargin{
	margin-left: 246px;
	margin-top: -17px;
}
.mtop12 {
	position: relative;
	/* margin-top:5px !important; */
	margin-bottom: 12px;
}
.mr2p{
	margin-right: -2px;
}
.iconUpDown{
	margin-top: -40px;
	cursor: pointer;
	opacity: 0.5;
	color: #7c8487d1;
	display: flex;
	justify-content: center; /* Center horizontally */
	align-items: center;    /* Center vertically */
	margin-right: 6px;
	}

.mr16px{
	margin-top: 16px;
}
.w40mr md-select-value .md-select-icon {
	width: 48px;
    margin-right: 7px;
    margin-left: 40px;
	color: #7c8487d1;
}

.mrlf64 md-select-value .md-select-icon{
margin-left: 64px;
    opacity: 0.5;
} 
.removeSelectionTest {
	margin-right: 10px;
	margin-top: 20px;
	margin-left: -22px;
	width: 10px;
	font-size: 12px !important;
	font-weight: 900 !important;
	color: #747C80;
}
.removeSelectionRec{
	margin-right: 10px;
	margin-top: 17px;
	margin-left: -22px;
	width: 10px;
	font-size: 12px !important;
	font-weight: 900 !important;
	color: #747C80;
}
.clrgray md-select[disabled] .md-select-value > span:not(.md-select-icon) {
	color: #7c8487d1;
}
.mrtop1a{
	margin-top: 35px;
}
.questionNumber1a {
	font-family: Montserrat;
	font-size: 15px;
	margin-top: 36px;
	font-weight: bold;
	text-align: center;
	line-height: 30px;
	color: #43494C;
}


.countryiconUpDown{
	border-radius: 50%;
	padding: 2px;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    /* UI Properties */
    border: 1px solid #008080;
    opacity: 1;
}


.lettername{
  border-radius: 50%;
  padding: 2px;
  
transform: matrix(-1, 0, 0, -1, 0, 0);
/* UI Properties */
border: 20px solid #008080;
opacity: 1;

  
}

.white-box-layer-2 {
  background-color: white;
  padding: 20px;
  margin-left: -56px;
}
.white-box-layer-1 {
  background-color: white;
  margin-left: -56px;
  margin-top: -29px;
  height: 18px;
}
.letterSpacing {
  position: relative;
  font-size: 20px;
  color: white;
  padding: 11px;
  font-family: Montserrat;
  bottom: -6px !important;
  left: 2px;

}

.scrollable-div {
height: 300px;
overflow-y: scroll;  /* Adds a vertical scroll bar */
}

.scrollable-div::-webkit-scrollbar {
  width: 10px;             /* Set the scrollbar width */
  height: 10px; 
}

.scrollable-div::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c2c9d2; 
}
.scrollable-div::-webkit-scrollbar-track {
  border-radius: 10px;     /* Rounded corners for the track */
}
.adminNameColor{
background-color: #407887 ;
border-radius: 50%;
  /* padding: 7px; */
  width: 40px;
  height: 40px;
}

.adminNameColorWhitefield{
background-color: #008080;
margin-left: 28px;
}


.minusIcon {
	background: url("../images/minus.svg") no-repeat 0px 0px;
    min-height: 32px;
	margin-top: 47px;
}

.plusIcon {
	background: url("../images/plus.svg") no-repeat 0px 0px;
    min-height: 32px;
	margin-top: 47px;
}

.countryAdd {
	background: url("../images/dna_conducted-48a4f07cf6-eb0f1ba79a.png") no-repeat 0px 0px;
    min-height: 32px;
}


.nameFont {
	margin: 10px;
	padding-right: 16px;
	padding-top: 7px;
	justify-content: space-between;
	top: 390px;
	left: 507px;
	height: 38px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #008080;
	border-radius: 20px;
}
.dynamic-line  {
		border-bottom: 1px solid #008080;
		flex-grow: 1;
		margin: 0px -10px;
		margin-right: 0px;
}

.container {
	display: flex;
	flex-wrap: wrap; /* Allows wrapping */
	justify-content: space-between; /* Spreads items with space in between */
}

.nameShow{
	margin-left: 14px !important;
	top: 20px;
	left: 20px;
	width: 111px;
	height: 22px;
	font-size: 15px;
	display: inline;
	font-family: Montserrat;
	text-align: left;
	color: #008080;
	opacity: 1;
}


.ng-letter-avatar{
	top: 365px;
	left: 447px;
	width: 50px;
	height: 50px;
	/* UI Properties */
	background: #407887 0% 0% no-repeat padding-box;
	opacity: 1;
}

.containeradmin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border: 1px solid black;
  }
.addEditPopupedit {
	max-width: 100%;
	max-height: 100%;
	width: 720px;
}
.underline {
    height: 1px; /* Thickness of the underline */
    background-color: rgb(119, 113, 113); /* Color of the underline */
    width: 110%; /* Full width */
    margin-top: -2px; /* Adjust spacing as needed */
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
/* md-tab */

.profileContent md-ink-bar {
    background: none !important;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    margin-left: 45px;
}

.profileContent md-tabs-wrapper {
    padding-left: 7.2%;
    background: #193549;
}

.profileContent md-tabs-wrapper {
    padding-left: 7.2%;
    background: #193549;
}

.act-mdlist > md-list, md-card {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}


/* scrollbar*/
::-webkit-scrollbar {
    background: transparent;
    width: 15px;
    height: 5px;
    /* box-shadow: inset 1px 1px 16px rgba(0,0,0,.1); */
    box-shadow: inset 1px 0 1px #E6E6E6, inset 0 0 14px #E6E6E6;
}

::-webkit-scrollbar-track {
    background: #E6E6E6;
    width: 15px;
    box-shadow: inset 1px 1px 16px #E6E6E6;;
    cursor: pointer;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* border: 5px solid #1F3348;*/
    border: 15px solid #1A364B;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}

::-webkit-scrollbar-button:start:decrement {
    background: url("../images/chevron-Inactive-up.png") no-repeat center;
    background-color: #E6E6E6;
    opacity: 0.3;
}

::-webkit-scrollbar-button:end:increment {
    background: url("../images/chevron-Inactive-down.png") no-repeat center;
    background-color: #E6E6E6;
    opacity: 0.3;

}

::-webkit-scrollbar-button:end:increment:hover {
    background: url("../images/chevron-active-down.png") no-repeat center;
    background-color: #E6E6E6;
    opacity: 1.0 !important;
}

::-webkit-scrollbar-button:start:decrement:hover {
    background: url("../images/chevron-active-up.png") no-repeat center;
    background-color: #E6E6E6;
    opacity: 1.0 !important;
}

/*internet explorer scrollbalken*/
body {
    scrollbar-base-color: #1F3348;
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #fff;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: #fff;
    scrollbar-shadow-color: #fff;
    scrollbar-dark-shadow-color: #1F3348;
}

#investigationFilterId, .prosHeadPart {
    scrollbar-base-color: #1F3348;
    scrollbar-3dlight-color: #f4f8f9;
    scrollbar-highlight-color: #f4f8f9;
    scrollbar-track-color: #f4f8f9;
    scrollbar-arrow-color: #f4f8f9;
    scrollbar-shadow-color: #f4f8f9;
    scrollbar-dark-shadow-color: #1F3348;
}

#investigationFilterId md-sidenav md-content, #userFilterId md-sidenav md-content, #entitiesFilterId md-sidenav md-content, #requestFilterId md-sidenav md-content {
    background: #f4f8f9 !important;
}

/* Placeholder */
::-webkit-input-placeholder {
    font-size: 15px;
}

::-moz-placeholder {
    font-size: 15px;
}

:-ms-input-placeholder {
    font-size: 15px;
}

#prosInvSearchId::-webkit-input-placeholder, #locationSearchId::-webkit-input-placeholder {
    font-size: 15px;
    color: #919CA1;
}

#prosInvSearchId::-moz-placeholder, #locationSearchId::-moz-placeholder {
    color: #919CA1;
    font-size: 15px;
}

#prosInvSearchId:-ms-input-placeholder, #locationSearchId::-ms-input-placeholder {
    color: #919CA1;
    font-size: 15px;
}

md-input-container .md-input#prosInvSearchId, md-input-container .md-input#locationSearchId {
    padding-bottom: 8px;
    border-bottom: 1px solid #C9CBCE;
    font-family: Montserrat;
    font-size: 15px;
    color: #919CA1;
    float: none;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
    border-bottom: 1px solid #C9CBCE;
}

md-input-container:not(.md-input-invalid).md-input-focused label {
    color: #919CA1;
}

.investigationContainer md-input-container {
    margin: 18px 0px 0px 0px !important;
}

.md-select-menu-container.md-active.md-clickable {
    transition: all 0.2s ease;
    min-width: 300px !important;
}

md-input-container .md-input {
    float: none;
}

.act-mdlist md-list-item.md-3-line {
    height: 80px;
    min-height: 80px;
}

.sortOrder .md-select-value, .sortOrder md-select:not([disabled]):focus .md-select-value {
    color: #7C8487 !important;
}

i, div {
    outline: none;
}

md-progress-linear.countListLoad {
    position: absolute;
    left: 0px;
    margin-top: 5px;
}

md-progress-linear.md-default-theme .md-bar, md-progress-linear .md-bar {
    background-color: #213B4F
}

md-progress-circular.md-hue-2 path {
    stroke: #231F20 !important;
}

md-progress-circular path {
    stroke: #231F20 !important;
}

/* md-select*/

md-select-header md-input-container input, md-select-header md-input-container .md-input::-moz-placeholder {
    color: #43494C !important;
}

md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder, md-select[disabled] .md-select-value > span:not(.md-select-icon) {
    color: #43494C;
}

md-select .md-select-value.md-select-placeholder, md-select:not([disabled]):focus .md-select-value.md-select-placeholder {
    color: #7C8487;
}

.md-select-value, md-select:not([disabled]):focus .md-select-value {
    color: #29B098;
}

md-select[disabled] .md-text {
    background-image: none;
    font-family: Lato Bold;
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    color: #2BB29A;
}

md-select[disabled] .md-select-value {
    background-image: none;
}

.md-select-value > span:not(.md-select-icon) {
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    /* letter-spacing: 0.5px; */
    white-space: normal;
	width: 174px !important;
}

.md-select-menu-container.md-active md-select-menu {
    width: 300px;
    margin-left: -1px;
    margin-top: -7px;
}

#select_container_45.md-select-menu-container.md-active md-select-menu, #select_container_34.md-select-menu-container.md-active md-select-menu, #select_container_43.md-select-menu-container.md-active md-select-menu {
    width: 300px;
}

#select_container_45 > md-select-menu md-content, #select_container_34 > md-select-menu md-content, #select_container_43 > md-select-menu md-content {
    width: 300px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
}

md-option .md-text {
    /* height: 16px; */
    font-family: Montserrat;
    font-size: 13px;
    line-height: 16px;
    color: #43494C;
}

md-select-menu md-content {
    width: 300px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
}

.md-select-value .md-select-icon {
    margin: 0 10px;
    opacity: 0.5;
}

.md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, md-select.ng-invalid.ng-touched .md-select-value {
    color: #7C8487 !important;
}

/*md-checkbox*/

#selectAll md-checkbox {
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 16px;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: relative;
    min-width: 20px;
    min-height: 20px;
    margin-left: 6px;
    margin-right: 4px;
}

md-checkbox .md-icon {
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    background-color: rgba(222, 225, 232, 0);
    border: 1px solid #C9CBCE;
    border-radius: 2px;
}

md-checkbox.md-checked .md-icon {
    background-color: #F5C727;
}

/*md-inputcontainer*/


md-input-container.md-default-theme .md-placeholder {
    color: #C9CBCE !important;
    font-size: 16px;
    font-family: Lato;
    line-height: 19px;
    letter-spacing: 1px;
}

md-input-container.hide-error-msg .md-errors-spacer:empty {
    display: none;
}

.addEditInvestigatorForm md-input-container .md-input[disabled], [disabled] md-input-container .md-input {
    border-bottom: 1px solid #E1E1E1;
    color: #43494C;
}

/*md-content*/
md-content.md-default-theme, md-content {
    background-color: #F4F8F9;
}

/*md-card*/
md-card.md-default-theme, md-card {
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

/*md-datepicker*/
.md-default-theme .md-datepicker-input-container.md-datepicker-invalid, .md-datepicker-input-container.md-datepicker-invalid {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

md-radio-button:not([disabled]).md-primary.md-checked .md-off {
    border-color: #2BB29A;
}

md-radio-button:not([disabled]).md-primary .md-on {
    background-color: #2BB29A;
}

md-input-container.md-input-focused input.test.md-input {
    border-bottom: 1px solid #2BB29A !important;
}

/*Add Investigator Popup*/
.addEditInvestigatorForm md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, .addEditInvestigatorForm md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after,
#familyMembers md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: none;
}

/* .addEditInvestigatorForm .md-select-value,md-select:not([disabled]):focus .md-select-value, md-select[disabled] .md-text {
	color: #919CA1;
} */
.addEditInvestigatorForm md-select {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
    margin-right: 10px;
    width: 90%;
}

.addEditInvestigatorForm input {
    width: 90%;
}

.requestLocationForm md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: none;
}

.requestLocationForm .md-select-value, md-select:not([disabled]):focus .md-select-value {
    color: #919CA1;
}

.requestLocationForm md-select {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
    margin-right: 10px;
    /* width:90%; */
}

.requestLocationForm input {
    /* width:90%; */
}

.requestLocationForm md-input-container .md-input[disabled], [disabled] md-input-container .md-input {
    border-bottom: 1px solid #E1E1E1;
}

.addEntityForm input {
    width: 100%;
}

.addEntityForm md-input-container .md-input[disabled], [disabled] md-input-container .md-input {
    border-bottom: 1px solid #E1E1E1;
}

.searchFilter md-autocomplete-wrap.md-whiteframe-z1 {
    box-shadow: none;
    height: inherit;
}

md-autocomplete.searchFilter input[type=search] {
    color: #43494c;
}

#requestEntityFormId md-input-container input, #requestEntityFormId md-select[disabled] .md-text, #requestEntityFormId .md-select-value > span:not(.md-select-icon),
#requestLocationFormId md-select[disabled] .md-text, #requestLocationFormId .md-select-value > span:not(.md-select-icon) {
    font-family: Montserrat;
    font-size: 15px;
    color: #43494C !important;
    font-weight: normal;
}

#requestEntityFormId md-select[disabled] .md-text, #requestEntityFormId .md-select-value > span:not(.md-select-icon),
#requestLocationFormId md-select[disabled] .md-text, #requestLocationFormId .md-select-value > span:not(.md-select-icon) {
    text-transform: capitalize;
}

/* .saveOrClose.md-button[disabled] {
	background-color:#ccc !important;
	color:rgba(0,0,0,0.38);
	cursor: no-drop;
} */
#apprvEntityReq md-input-container {
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    margin-bottom: 0 !important;
    margin-top: -6px;
}

#apprvLocReq md-input-container {
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: -6px !important;
}

.req-entity md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
    background-color: #53B49C !important;
}

.req-entity md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
    background-color: #53B49C !important;
}

#addNewEntityPopupId md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: none !important;
}

md-input-container textarea.md-input, md-input-container textarea.md-input:focus, md-input-container:not(.md-input-invalid).md-input-focused textarea.md-input {
    border: 1px solid #E1E1E1;
    outline: 1px;
}

md-input-container.md-input-invalid textarea.md-input {
    border: 1px solid rgb(221, 44, 0) !important;
}

#addNewEntityPopupId .md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder,
#apprvEntityReq md-select[disabled] .md-select-value > span:not(.md-select-icon), #apprvEntityReq .md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder,
#requestLocationFormId md-select[disabled] .md-select-value > span:not(.md-select-icon), #requestLocationFormId .md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder,
#addEntityFormId md-select[disabled] .md-select-value > span:not(.md-select-icon), #addEntityFormId .md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder, #addEntityFormId md-select[disabled] .md-text,
#addlocationRequestFormId md-select[disabled] .md-select-value > span:not(.md-select-icon), #addlocationRequestFormId .md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder, #addlocationRequestFormId md-select[disabled] .md-text {
    /* color: #7C8487 !important; */
    color: #43494C;
}

#addNewEntityPopupId .md-select-value, #addNewEntityPopupId md-select:not([disabled]):focus .md-select-value {
    /* color: #7C8487 !important; */
    color: #43494C;
}

#entityNameId md-select {
    width: 90%;
}

.locationFilter md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: none !important;
}

#addEditInvestigatorPopupId md-select[disabled] .md-text, #addEditInvestigatorPopupId .md-select-value > span:not(.md-select-icon),
#addEditInvestigatorPopupId .md-select-value, #addEditInvestigatorPopupId md-select:not([disabled]):focus .md-select-value,
#addNewEntityPopupId md-select[disabled] .md-text, #addNewEntityPopupId .md-select-value > span:not(.md-select-icon)
    /* #locationRequestPopupId md-select[disabled] .md-text, #locationRequestPopupId .md-select-value > span:not(.md-select-icon) */
{
    font-family: Montserrat;
    font-size: 15px;
    color: #43494C !important;
    text-transform: capitalize;
    font-weight: normal;
}

.addlocationRequestForm md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, .addlocationRequestForm md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: none;
}

#addlocationRequestFormId .md-select-value > span:not(.md-select-icon) {
    font-family: Montserrat !important;
    font-size: 13px;
    line-height: 15px;
    color: #43494C !important;
    font-weight: 300;
}

/* #addlocationRequestFormId .md-select-value,#addlocationRequestFormId md-select:not([disabled]):focus .md-select-value,
#addNewEntityPopupId .md-select-value, #addNewEntityPopupId md-select:not([disabled]):focus .md-select-value
{
	color: #7C8487 !important;
} */
#addlocationRequestFormId .md-select-value, #addlocationRequestFormId md-select:not([disabled]):focus .md-select-value {
    color: #43494C !important;
}

.addlocationRequestForm .md-select-menu-container.md-active md-select-menu {
    width: 100% !important;
}

.addlocationRequestForm .md-select-menu-container md-content {
    width: 100% !important;
}

/* #addlocationRequestFormId md-input-container.md-default-theme .md-input,#addlocationRequestFormId md-input-container .md-input,
#addNewEntityPopupId md-input-container.md-default-theme .md-input,#addlocationRequestFormId md-input-container .md-input{
	color: #7C8487 !important;
} */
.changeWhiteFrame .md-input-container.md-default-theme label, .changeWhiteFrame md-input-container label, .changeWhiteFrame md-input-container.md-default-theme .md-placeholder, .changeWhiteFrame md-input-container .md-placeholder {
    display: none;
}

.changeWhiteFrame .md-autocomplete.md-autocomplete-wrap.md-input-container.md-input-focused.md-input {
    border-bottom: 1px solid #2BB29A !important;
}

/* Request Entity Popup Accordion Starts */
#requestEntityFormId v-accordion, #requestLocationFormId v-accordion {
    display: inline-table;
    width: 100%;
}

#addlocationRequestFormId md-input-container.locationPopInputSty input {
    font-family: Montserrat !important;
    font-size: 13px;
    line-height: 15px;
    color: #43494C !important;
    font-weight: 300;
}

.compeleteProfAccord .vAccordion--default v-pane.is-expanded > v-pane-header {
    border-bottom-color: #C9CBCE !important;
}

.compeleteProfAccord .vAccordion--default v-pane-header::after, .compeleteProfAccord .vAccordion--default v-pane-header::before {
    background-color: #fff !important;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: #F5C727 !important;
}

#addNewEntityPopupId #select_container_43.md-select-menu-container.md-active md-select-menu,
#addNewEntityPopupId #select_container_43 > md-select-menu md-content {
    width: 300px !important;
}

/* Notification message */
.notifications .success {
    background-color: #2BB29A !important;
}

.notifications .close-click {
    margin-right: -25% !important;
    margin-left: 30% !important;
}


#neighborhoodLocation .filterSubHeading {
    height: 15px;
    font-family: Montserrat;
    font-size: 12.5px;
    font-weight: normal;
    line-height: 15px;
    color: #919CA1;
}

#neighborhoodLocation .popupFont {
    color: #919CA1 !important;
}

#neighborhoodLocation .md-select-value > span:not(.md-select-icon) {
    font-family: Montserrat;
    font-size: 14px;
    color: #716e6e;
    font-weight: 300;
}

.dropdown-search {
    width: 299px;
    margin: -10px 0px 0px -2px;
}

md-select-menu {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    max-height: 256px;
    min-height: 48px;
    overflow-y: hidden;
    transform-origin: left top;
    transform: scale(1);
}

.searchNotification {
    height: 15px;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #BABABA;
    text-align: left;
    margin-left: 14px;
}

.othersInput {
    padding-left: 6px;
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
    padding-bottom: 5px;
}

#checkBoxContentStyle md-divider {
    width: 99px;
    height: 0px;
    margin-left: 5px;
    margin-top: 7px;
    position: relative;
    border-width: thin;
    border-color: #98a9b6;
    margin-right: 5px;
}

md-checkbox.OtherWhiteCheckbox.md-checked .md-icon {
    background-color: #C5C2C3 !important;
}

md-checkbox.OtherWhiteCheckbox .md-icon {
    border-color: #C5C2C3 !important;
}

.OtherWhiteCheckbox {
    margin-top: -3px;
}

md-input-container.md-default-theme .md-input, md-input-container .md-input {
    border-color: #696969 !important;
}

#familyMembers .md-select-value, md-select:not([disabled]):focus .md-select-value, #familyMembers md-select[disabled] .md-text {
    color: rgba(0, 0, 0, 0.87);
}

#familyMembers .md-select-value > span:not(.md-select-icon), #familyMembers md-select[disabled] .md-text {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 100%;
    font-weight: normal;
}


.md-open-menu-container.md-active {
    margin-left: -65px;
    margin-top: 40px;
}

#sideComment .md-button {
    min-width: 70px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

#sideComment .md-button.md-default-theme:not([disabled]):hover, #sideComment .md-button:not([disabled]):hover,
#sideCommentReply .md-button.md-default-theme:not([disabled]):hover, #sideCommentReply .md-button:not([disabled]):hover {
    background-color: transparent !important;
}

#sideComment md-progress-circular.md-hue-2 path {
    stroke: #2BB29A !important;
}

#sideComment md-progress-circular path {
    stroke: #2BB29A !important;
}

#sideCommentSave .md-button[disabled], #sideCommentReply .md-button[disabled] {
    color: #2BB29A !important;
}

@media only screen and (max-width: 650px) and (min-width: 300px) {
    .act-mdlist md-list-item.md-3-line {
        height: 135px;
        min-height: 135px;
    }
}

#investigationListing .md-select-value .md-select-icon {
    display: none;
}

#investigationListing .md-select-value > span:not(.md-select-icon) {
    display: none;
}

#rcDetails md-datepicker[disabled] .md-datepicker-triangle-button {
    display: inherit !important;
}

#rcDetails md-input-container.md-default-theme .md-input[disabled],
#rcDetails md-input-container .md-input[disabled],
#rcDetails [disabled] md-input-container.md-default-theme .md-input,
#rcDetails [disabled] md-input-container .md-input {
    color: rgba(0, 0, 0, 0.87) !important;
}

.religious md-input-container {
    margin: 0px !important;
}

.birthmedical md-input-container {
    margin: 0px !important;
}

#religiousInstitution md-input-container input[type="search"],
#birthMedical md-input-container input[type="search"] {
    margin-bottom: 20px;
}

#openForm .md-button.md-default-theme:not([disabled]).md-icon-button:hover,
#openForm .md-button:not([disabled]).md-icon-button:hover {
    background-color: #f5c727;
}

#warningPopupId .md-button[disabled] {
    color: #3A3A3A;
}

#investigationFilterId md-checkbox.md-default-theme[disabled] .md-label, #investigationFilterId md-checkbox[disabled] .md-label,
#requestFilterId md-checkbox.md-default-theme[disabled] .md-label, #requestFilterId md-checkbox[disabled] .md-label,
#userFilterId md-checkbox.md-default-theme[disabled] .md-label, #userFilterId md-checkbox[disabled] .md-label,
#entitiesFilterId md-checkbox.md-default-theme[disabled] .md-label, #entitiesFilterId md-checkbox[disabled] .md-label {
    color: #43494C;
}

#investigationFilterId md-checkbox.md-default-theme[disabled].md-checked .md-icon, #investigationFilterId md-checkbox[disabled].md-checked .md-icon,
#requestFilterId md-checkbox.md-default-theme[disabled].md-checked .md-icon, #requestFilterId md-checkbox[disabled].md-checked .md-icon,
#userFilterId md-checkbox.md-default-theme[disabled].md-checked .md-icon, #userFilterId md-checkbox[disabled].md-checked .md-icon,
#entitiesFilterId md-checkbox.md-default-theme[disabled].md-checked .md-icon, #entitiesFilterId md-checkbox[disabled].md-checked .md-icon {
    background-color: #F5C727
}

#investigationFilterId md-checkbox[disabled], #requestFilterId md-checkbox[disabled], #userFilterId md-checkbox[disabled],
#requestFilterId md-switch[disabled], #requestFilterId md-switch[disabled] .md-container,
#entitiesFilterId md-checkbox[disabled] {
    cursor: not-allowed !important;
}

#requestFilterId md-switch[disabled] .md-thumb {
    background-color: rgb(250, 250, 250);
}

#requestFilterId md-switch[disabled] .md-bar {
    background-color: rgb(158, 158, 158);
}

#importFamilyDetailsId md-divider {
    border: 1px solid #C9CBCE;
}

.regionStickySearch {
    max-height: 180px;
    overflow-y: auto;
    width: 100%;
}

.dashboardDateFilter {
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
}

.noVScroll {
    overflow: hidden !important;
}

.searchNotExists {
    font-size: 17px;
    color: #43494c;
    margin-top: 10%;
}

.marginAlignment {
    margin-top: -18px;
}

.searchAlignment {
    margin-top: 1px;
    margin-left: 25px;
}

.searchTextAlign {
    margin-left: 25px;
    padding-bottom: 8px;
    border-bottom: 1px solid #C9CBCE;
    font-family: Montserrat;
    font-size: 15px;
    color: #919CA1;
    float: none;
}

.searchExists {
    margin-left: 25px;
}

.md-select-value > span:not(.md-select-icon) {
	font-family: 'Lato';
    font-size: 12.4px;
    font-weight: 600;
    line-height: 14px;
    margin-top: -7px;
    letter-spacing: 0.5px;
}
.fontstyle md-select-value > span:not(.md-select-icon) {
	font-family: 'Montserrat';
    font-size: 12.4px;
    font-weight: 600;
    line-height: 14px;
    margin-top: -7px;
    letter-spacing: 0.5px;
}
md-select .md-select-value.md-select-placeholder{
	color: #7c8487d1;
}

.editViewmobile,
.editMobilesview {
	margin-left: 20px;
	margin-right: 20px;
	display: none;
}
.hide{
	display: inline-block;
}

 @media (max-width: 600px) {
    .editViewmobile,
	.editMobilesview {
        display: block;
		
    }

	.hide {
		display: none;
	}
} 

.countrySelection md-select[aria-expanded="false"] .md-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  }

  
.countrySelection md-select:hover .md-text {
	white-space: normal;
  }


.custom-select md-select-value span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
	
  }
  
  /* Add this style to show the full list on hover */
.custom-select:hover md-select-value span {
	white-space: normal;
  }


.md-select-menu-container md-option #borderValue{
	border-bottom:1px solid #2BB29A !important;

}
.dateBottom md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
	margin-bottom: -2px !important;
}

.greenFilter md-select-value,
md-select:not([disabled]):focus .md-select-value {
	color: #29B098;
}

.letterColor md-select-value,
.filterBlack md-select:not([disabled]):focus .md-select-value {
	color: #43494C;
}
.widthDate ._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
	width: 133px;
}

.editViewmobilebutton{
    margin-left: 100px;
    display: none;
    position: fixed;
	margin-top: -29px;
}

@media (max-width: 600px) {
	.editViewmobilebutton{
        display: block;
        right: 30px;
        width: 40px !important;
        height: 40px !important;
        margin-top: -20px
    	}
	.hide {
	    display: none;
	}
}

@media (max-width: 600px) {
	.marginleft30{
		margin-left: 30px;
	}
}
@media (max-width: 600px) {
	.marleft30{
		margin-left: 30px;
	}
}


.dnadropdownSelectionvalue md-select .md-select-value.md-select-placeholder{
	font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    line-height: 16px; 
	color:#43494C;
	margin-top: -9px;
	margin-left: -3px;
}

.dropdownSelection .md-select-value > span:not(.md-select-icon) {
	font-family: 'Lato';
    font-size: 12.4px;
    font-weight: 600;
    line-height: 14px;
    margin-top: 3px;
    letter-spacing: normal;
}
.dropdownSelectionFont .md-select-value > span:not(.md-select-icon) {
	font-family: 'Lato';
    font-size: 12.4px;
    font-weight: 600;
    line-height: 14px;
    margin-top: 3px;
    letter-spacing: 0.5px;
	letter-spacing: normal;
}

.testdropdown{
	margin-left: 100px;
}
md-select .md-select-value  .colorBlack
{
	color: #43494C;
}
 md-select[disabled] .md-select-value > span:not(.md-select-icon).mobileView{
	display: none;
}
 .w100mr md-select[disabled] .md-select-value > span:not(.md-select-icon) {
    color: #43494C;
}
.w100mr md-select-value .md-select-icon{
	width: 48px;
    margin-right: 7px;
    opacity: 0.5;
    color: black;
    margin-left: 12px;
}

.w200mr md-select-value .md-select-icon{
	width: 48px;
    margin-right: 7px;
    opacity: 0.5;	
    color: black;
    margin-left: 12px;
}	
.w48mr md-select-value .md-select-icon {
	width: 48px;
    margin-right: 7px;
    margin-left: 12px;
	color:black;
}
.dnaTestRecivedOpenInvestigation md-select-value .md-select-icon{
	width: 77px;
    opacity: 0.5;
}
.openDateofdnaontestrecived md-select-value .md-select-icon{
	width: 39px;
    opacity: 0.5;
    margin-left: 12px;
    margin-right: 1;
}
.closedDateofdnatestrecived md-select-value .md-select-icon{
    opacity: 0.5;
	margin-left: 27px;
    margin-right: 1px;
	width: 32px;
    color: black;
}
.dropdownDnaSelectionValue md-select-value .md-select-icon{
	width: 35px;
    opacity: 0.5;
	color: black;
}
.closeddnaDateontestrecived md-select-value .md-select-icon {
	opacity: 0.5;
    margin-left: 27px;
    margin-right: 1px;
	width: 32px;
	color: black;
}
.closedDateofdnaontestrecived md-select-value .md-select-icon {
	opacity: 0.5;
    margin-left: 27px;
    margin-right: 1px;
	width: 32px;
	color: black;
}
.closedDnaofdateontestrecived md-select-value .md-select-icon {
	opacity: 0.5;
    margin-left: 27px;
    margin-right: 1px;
	width: 34.5px;
	color: black;
}
.closedDateoftestrecived md-select-value .md-select-icon{
	margin-right: -14px;
    opacity: 0.5;
}
.test678 md-select-value .md-select-icon{
	width: 76px;
    opacity: 0.5;	
}
@media screen and (max-width: 1251px) {

    .dnadropdownSelectionvalue {
	  width: 84%; 
	}
  	
	.dateFilter {
	  flex: 1 1 100%; 
	}
  	
	.hideIcon {
	  display: inline-block;
	}
	}
	 @media screen and (max-width: 960px){
	.dropdowninSelection {
	  width: 88%; 
	}
	}
@media screen and (max-width: 960px){
	.dropdownonSelection {
	  width: 85%; 
	}
}
@media screen and (max-width: 960px){
	.dropdownoninSelection  {
	  width: 112%; 
	}
}
@media screen and (max-width: 960px){
	.closedDateofdnatestrecived {
	  width: 102%; 
	}
}
 @media screen and (max-width: 960px){
	.w100mr {
	  width: 105%; 
	}
} 
@media screen and (max-width: 960px){
	.w200mr {
	  width: 98%; 
	}
} 

 @media (max-width: 599px){
.flex-xs-50 {
    flex: 1 1 50%;
    max-width: 105%;
    max-height: 100%;
    box-sizing: border-box;
}}
.reduceHeight{
	height: 80px;
}
.borderColorRed {
    border-color: red;
}
.colorChange md-select:not([disabled]):focus .md-select-value.md-select-placeholder{
	color: #43494C;
}
.borderline{
	margin-top: -18px;
}


.dnaErrorMsg{
	margin-top: -20px !important;
    color: #dd2c00 !important;
}
.dnaCenterErrorMsg{
	margin-top: -37px !important;
    color: #dd2c00 !important;
}

.borderError md-input-container .md-input{
    border-color: #dd2c00!important; 
}

.borderError md-autocomplete md-autocomplete-wrap  md-input-container .md-input{
    border-color: #dd2c00!important; 
}
	

.w100mr md-select:not([disabled]):focus .md-select-value.md-select-placeholder{
	color: #43494C;
 }
 .closedDateofdnatestrecived md-select:not([disabled]):focus .md-select-value.md-select-placeholder{
	color: #43494C;
 }
 .w48mr md-select:not([disabled]):focus .md-select-value.md-select-placeholder{
	color: #43494C;
 }
 .closedDateofdnaontestrecived md-select:not([disabled]):focus .md-select-value.md-select-placeholder{
	color: #43494C;
 }
 .marginright{
	margin-top: 10px;
    margin-left: -2px;
 }
 .mr64p md-select-value .md-select-icon{
	margin-left: 64px;
 }
 .dropdownSelectionCenter  md-select .md-select-value{
	margin-bottom: -4px;
}
.w40mrg md-select-value .md-select-icon {
    width: 48px;
    margin-right: 7px;
    opacity: 0.5;
    color: #7c8487d1;
    margin-left: 40px;
	margin-top: 5px;
}
.disBlock{
	display: block;
}
@media screen and (max-width: 960px){
	.dropdowninTestSelection {
	  width: 100%; 
	}
	}
@media screen and (max-width: 1290px){
	.dropdowninRecSelection {
	  width: 100%; 
	}
	}	
	@media (max-width: 200px) {
		.iconUpDown {
			justify-content: flex-start; /* Align left on smaller screens */
		}
	}

	@media (min-width: 200px) {
		.iconUpDown {
			justify-content: flex-end; /* Align right on larger screens */
		}
	}
	@media screen and (max-width: 1290px){
		.mrlf64 {
			width: 102% /* Align left on smaller screens */
		}
	}
	.mrright43 md-select-value .md-select-icon {
		margin-left: 66px;
	}

	@media screen and (max-width: 900px){
		.dnaTestRecivedOpenInvestigation {
		  width: 101%; 
		}
		}	

	@media (max-width: 600px) {
		.dnaPending {
			width: 50px;
		}
		}
		  
		.dnaPending {
			display: block; /* Ensures the element is visible */
		}
	@media (max-width: 600px) {
		.dnaConducted {
			width: 50px;
			}
			}
			  
		.dnaConducted {
			display: block; /* Ensures the element is visible */
			}

            .colourletter md-select-value, .filterBlack md-select:not([disabled]):focus .md-select-value {
				color: #43494C;
				min-height: 1px !important;
			}
				
			.widbutton{
				width: 36px;
    height: 36px;
    margin-left: -2px;
			}
			.widtheditbutton{
				width: 40px;
    height: 40px;
			}

			@media (max-width: 600px) {
				.minusIcon{
					min-height: 32px;
    margin-top: 8px;
				}
			}
			@media (max-width: 600px) {
				.plusIcon{
					min-height: 32px;
    margin-top: 8px;
				}
			}
			@media (max-width: 600px) {
			.marginmedia{
				margin-left: 21px;
				margin-top: 17px;
				margin-bottom: 9px;
			}
		}
		@media (max-width: 600px) {
		.w100Impbot18 {
			width: 100% !important;
			margin-bottom: 18px;

		}
    }

